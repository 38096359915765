import React, {useEffect} from 'react';
import Icon from "../../../../images/confirmIcon.png"

const MainConfirmationRedirect = () => {
  const currentWindow = window.innerWidth;

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace('https://creativeconnex.ca')
    }, 5000);
    return () => {
      clearTimeout(timeout)
    };
  }, []);

  var redirectMessage={
    color: 'gray'
  }
  var confirmationTitle={
    padding: 20
  }
  var imgStyle={
    width: 200,
    height: 230,
    alt: "Creative Connex Icon",
    paddingBottom: 40
  }
  var counterStyle={
    fontWeight: 'bold',
    color: 'black'
  }
  var timeLeft = 5;
  var infoWrapper={
    marginLeft: 0,
    marginRight: 0
  }
  var infoWrapperResp={
    marginLeft: 10,
    marginRight: 10
  }


  function countdown() {
    timeLeft--;
    document.getElementById("seconds").innerHTML = String( timeLeft );
    if (timeLeft > 0) {
      setTimeout(countdown, 1000);
    }
  };
  setTimeout(countdown, 1000);
    return (
      <div>
        <h2 style={confirmationTitle}>Your Booking has been received by our team!</h2>
        <img src={Icon} style={imgStyle}/>
        <div style={currentWindow >= 720 ? infoWrapper : infoWrapperResp}>
          <h5>You will receive an email with your receipt and further instructions</h5>
          <p style={redirectMessage}>You will be redirect to the home page in <span id="seconds" style={counterStyle}>5</span> seconds</p>
        </div>
        <br/> 
      </div>
    )
}
export default MainConfirmationRedirect
