import React, { useContext } from 'react';
import FormContext from '../../../FormContext/form.context';
import {FaUserAlt} from 'react-icons/fa'
import ErrorPage from '../../ErrorPage';
import Loader from '../../Loading';
import useEmployees from '../../../../hooks/actions/employees/get';

const MainStaff = () => {
  const { staff, setStaff, setStaffId, page, setPage, staffId, setDateFixed } = useContext(FormContext);
  const currentWindow = window.innerWidth;
  var gridContainerMain = {
    padding: 0,
    borderRadius: 90,
    marginBottom: 20
  };
  var gridItemMain = {
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: 15,
    textAlign: 'start', 
    border: '2px hidden red'
  }
  var gridItemMainResp = {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 15,
    textAlign: 'start', 
    border: '2px hidden red'
  }
  var itemsArea={
    padding: 0
  }
  var singleItemArea={
    marginLeft: 0,
    marginRight: 0
  }
  var staffName={
    paddingLeft: 10,
    fontSize: 20
  }
  var staffNameResp={
    paddingLeft: '8vw',
    fontSize: 18,
    paddingTop: 10
  }
  var staffIcon={
    marginLeft: 10,
    width: '4vw',
    height: '4vh'
  }
  var staffIconResp={
    marginLeft: 20,
    width: '6vw',
    height: '6vh'
  }
  var buttonArea={
    paddingBottom: 20,
    paddingTop: 20
  }
  var buttonStyle={
    width: '15vw',
    height: '6vh'
  }
  var buttonStyleResp={
    width: '60vw',
    height: '6vh',
    marginBottom: '3vh'
  }
  var infoStyling={
    color: 'grey',
    fontSize: 14
  }
  var infoStylingResp={
    color: 'grey',
    fontSize: 12
  }
  const { isEmployeeLoading, hasEmployeeError, employeeData } = useEmployees();
  
  if (isEmployeeLoading) {
    return <Loader />;
  } else if (hasEmployeeError) {
    return <ErrorPage />;
  } else if (employeeData.length === 0) {
    return <ErrorPage />;
  }

  const filteredEmployees = employeeData.data &&
  employeeData.data
    .filter(item => item.attributes.availabilities.data.length > 0)
    .map(item => {
      // specify what to do with the availabilities array here
      return {
        ...item,
        // example: set availabilities to an empty array
        attributes: {
          ...item.attributes,
          availabilities: []
        }
      };
    });

  return (
    <div className={'container'} style={itemsArea}>
      <div className="row" style={gridContainerMain}>
        <span style={currentWindow >= 720 ? infoStyling : infoStylingResp}>Click on the desired staff member below</span>
        {filteredEmployees && filteredEmployees.map((item) => (
          <div key={item.id} className="row" style={singleItemArea}>
            <button
              className={staff === item.attributes.fullname ? 'border-top border-bottom' : 'bg-white border-top border-bottom'}
                onClick={function () {
                  if(staffId !== item.id)
                  {
                    setDateFixed(null)
                  }
                  setStaff(item.attributes.fullname);
                  setStaffId(item.id);
                  setPage(page + 1);
                }}
                style={currentWindow >= 720 ? gridItemMain : gridItemMainResp}>
                  <div className='row'>
                    <div className='col-1'>
                    <FaUserAlt className='d-sm-inline-block' style={currentWindow >= 720 ? staffIcon : staffIconResp}></FaUserAlt>
                    </div>
                    <div className='col'>
                    <div className='d-sm-inline-block' style={currentWindow >= 720 ? staffName : staffNameResp}>{item.attributes.fullname}</div>
                    </div>
                  </div>
                         
            </button>         
          </div>
        ))}
      </div>
      <div style={buttonArea}>
        {page > 1 && page !== 4 && (
          <button className="btn btn-secondary mx-2 shadow" onClick={() => {
            const nextPage = page - 1;
              setPage(nextPage);
          }} style={currentWindow >= 720 ? buttonStyle : buttonStyleResp}> 
            Back 
          </button>
        )}
        {page < 4  && staff !== "" && (
          <button className="btn btn-primary mx-2 shadow" onClick={() => {
            const nextPage = page + 1;
              setPage(nextPage);
          }} style={currentWindow >= 720 ? buttonStyle : buttonStyleResp}>
            Next
          </button>
        )}
      </div>     
    </div>
  );
};
export default MainStaff;


