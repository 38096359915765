import React, { useState } from 'react';
import '../MainEmployees/style.css';
import Loader from '../../Homepage/Loading';
import ErrorPage from '../../Homepage/ErrorPage';
import Header from '../../Homepage/Header/index';
import Footer from '../../Homepage/Footer/index';
import { Link } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import ModalServices from './MainServicesModal';
import { ToastContainer } from 'react-toastify';
import { createService } from '../../../hooks/actions/serv/create';
import { deleteService } from '../../../hooks/actions/serv/delete';
import { updateService } from '../../../hooks/actions/serv/update';
import useServices from '../../../hooks/actions/serv/get';
import RefreshButton from '../RefreshButton';

function MainServices() {
  const currentWindow = window.innerWidth;
  const { isServiceLoading, hasServiceError, serviceData } = useServices();
  const [modalState, setModalState] = useState(false);
  const [selected, setSelected] = useState(null);
  const [modalStateCreate, setModalStateCreate] = useState(false);

  //API status messages
  if (isServiceLoading) {
    return <Loader />;
  } else if (hasServiceError) {
    return <ErrorPage />;
  }

  var backgroundSettings = {
    backgroundImage:
      'linear-gradient(to top, #fdd570, #fdce61, #fcc752, #fcc042, #fcb931)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    /* end body required properties */
    margin: 'auto',
  };
  var mainFrame = {
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 20,
    width: '70%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '12vh',
    marginBottom: '20vh',
    flexGrow: 1,
    minHeight: '50vh',
  }
  var mainFrameResp = {
    backgroundColor: 'white',
    paddingTop: 20,
    borderRadius: 20,
    width: '95%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 30,
    marginBottom: '3vh',
    flexGrow: 1,
    minHeight: '50vh',
  }
  var mainFrameWrapper = {
    minHeight: '10vh',
  };
  var actionButtons = {
    marginLeft: 5,
    marginRight: 5,
  };

  const handleSelected = (record) => {
    setSelected(record);
    setModalState(true);
  };

  const handleCreateButtonSelected = () => {
    setModalStateCreate(true);
  };

  return (
    <div style={backgroundSettings}>
      <Header />
      <ToastContainer />
      {selected && (
        <ModalServices
          modalState={modalState}
          handleClose={() => {
            setModalState(false);
            setSelected(null);
          }}
          handleSave={(id, formData) =>
            updateService(id, formData.name, formData.description)
          }
          handleDelete={() => deleteService(selected?.id)}
          selected={selected}
          isCreateModal={false}
        />
      )}

      <ModalServices
        modalState={modalStateCreate}
        handleClose={() => setModalStateCreate(false)}
        handleSave={(formData) =>
          createService(formData.name, formData.description)
        }
        isCreateModal={true}
      />

      <div style={mainFrameWrapper}>
        <div style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}>
          <Link
            to="/admin-settings"
            className="d-flex justify-content-start"
            style={{ width: '4vh', height: '3vw' }}
          >
            <IoMdArrowRoundBack
              style={{ width: 40, height: 40, color: 'black' }}
            />
          </Link>
        </div>
        <div style={currentWindow >= 720 ? mainFrame : mainFrameResp}>
          <h3>Category Management</h3>
          <div className="crud-table">
            <div className='row'>
              <div className='col'>
                <RefreshButton />
              </div>
              <div className='col'>
              <div className="text-end">
              <button
                className="btn btn-success"
                style={{ width: '10em' }}
                onClick={handleCreateButtonSelected}
              >
                New Category
              </button>
            </div>
              </div>
            </div>          
            {hasServiceError && (
              <div className="error">Error: {hasServiceError.message}</div>
            )}
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col" className="text-center text-light">
                    ID
                  </th>
                  <th scope="col" className="text-center text-light">
                    Name
                  </th>
                  <th scope="col" className="text-center text-light">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {serviceData &&
                  serviceData.data.map((record) => (
                    <tr key={record.id}>
                      <td className="text-center">{record.id}</td>
                      <td>{record.attributes.name}</td>
                      <td className="text-center">
                        <button
                          style={actionButtons}
                          className="btn btn-primary"
                          onClick={() => {
                            handleSelected(record);
                          }}
                        >
                          Details
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MainServices;
