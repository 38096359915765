import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../MainBookings/style.css';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ErrorPage from '../../Homepage/ErrorPage';
import { deleteBooking } from '../../../hooks/actions/bookings/delete';
import { updateBooking } from '../../../hooks/actions/bookings/update';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useBookings from '../../../hooks/actions/bookings/get';
import Loader from '../../Homepage/Loading';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { GrClose } from 'react-icons/gr';
import { createBooking } from '../../../hooks/actions/bookings/create';
import DatePicker from 'react-datepicker';
import MainBookingsMobile from './MainBookingsMobile';
import useEmployees from '../../../hooks/actions/employees/get';
import useServices from '../../../hooks/actions/serv/get';
import useSubServices from '../../../hooks/actions/subserv/get';
import RefreshButton from '../RefreshButton';
import useUsers from '../../../hooks/actions/authentication/users';

const localizer = momentLocalizer(moment);

const Bookings = () => {
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const [modalState, setModalState] = useState(false);
  const [modalStateCreate, setModalStateCreate] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [selectedSubService, setSelectedSubService] = useState('');
  const [selectedCustomerName, setSelectedCustomerName] = useState('');
  const [selectedCreateMode, setSelectedCreateMode] = useState(false);

  const { loading, error, data } = useBookings();
  const { isEmployeeLoading, hasEmployeeError, employeeData } = useEmployees();
  const { isServiceLoading, hasServiceError, serviceData } = useServices();
  const { loadingUser, errorUser, usersData } = useUsers();
  const { isSubServiceLoading, hasSubServiceError, subServiceData } =
    useSubServices();

  const currentWindow = window.innerWidth;

  //API status messages
  if (
    loading ||
    isEmployeeLoading ||
    isServiceLoading ||
    isSubServiceLoading ||
    loadingUser
  ) {
    return <Loader />;
  } else if (
    error ||
    hasEmployeeError ||
    hasServiceError ||
    hasSubServiceError ||
    errorUser
  ) {
    return <ErrorPage />;
  }

  let newBookings;
  let employees;
  let employeesInfo;
  let services;
  let servicesInfo;
  let subservices;
  let subServicesInfo;
  let customers;
  let usersInfo;

  try {
    newBookings = data.data.map((booking) => ({
      id: booking.id,
      start: new Date(booking.attributes.bookingdate),
      end: new Date(
        new Date(booking.attributes.bookingdate).setHours(
          new Date(booking.attributes.bookingdate).getHours() + 1
        )
      ),
      title: `${booking.attributes.user.data.attributes.name}, ${booking.attributes.service.data.attributes.name}`,
      employee: `${booking.attributes.employee.data.attributes.fullname}`,
      employeeId: `${booking.attributes.employee.data.id}`,
      customer: `${booking.attributes.user.data.attributes.name}`,
      customerId: `${booking.attributes.user.data.id}`,
      phone: `${booking.attributes.user.data.attributes.phone}`,
      email: `${booking.attributes.user.data.attributes.email}`,
      service: `${booking.attributes.service.data.attributes.name}`,
      subservice: `${booking.attributes.subservice.data.attributes.name}`,
      serviceId: `${booking.attributes.service.data.id}`,
      subserviceId: `${booking.attributes.subservice.data.id}`,
    }));
  } catch {
    return <ErrorPage />;
  }

  try {
    employees = data.data.reduce((acc, curr) => {
      const employee = curr.attributes.employee.data.attributes.fullname;
      if (!acc.includes(employee)) {
        acc.push(employee);
      }
      return acc;
    }, []);

    employeesInfo = employeeData.data.reduce((acc, curr) => {
      const employeeName = curr.attributes.fullname;
      const employeeId = curr.id;
      const employeeObj = { id: employeeId, name: employeeName };
      if (!acc.some((obj) => obj.id === employeeId)) {
        acc.push(employeeObj);
      }
      return acc;
    }, []);

    services = data.data.reduce((acc, curr) => {
      const service = curr.attributes.service.data.attributes.name;
      if (!acc.includes(service)) {
        acc.push(service);
      }
      return acc;
    }, []);

    servicesInfo = serviceData.data.reduce((acc, curr) => {
      const serviceName = curr.attributes.name;
      const serviceId = curr.id;
      const serviceObj = { id: serviceId, name: serviceName };
      if (!acc.some((obj) => obj.id === serviceId)) {
        acc.push(serviceObj);
      }
      return acc;
    }, []);

    subservices = data.data.reduce((acc, curr) => {
      const subservice = curr.attributes.subservice.data.attributes.name;
      if (!acc.includes(subservice)) {
        acc.push(subservice);
      }
      return acc;
    }, []);

    subServicesInfo = subServiceData.data.reduce((acc, curr) => {
      const subServiceName = curr.attributes.name;
      const subServiceId = curr.id;
      const subServiceObj = { id: subServiceId, name: subServiceName };
      if (!acc.some((obj) => obj.id === subServiceId)) {
        acc.push(subServiceObj);
      }
      return acc;
    }, []);

    customers = data.data.reduce((acc, curr) => {
      const customer = curr.attributes.user.data.attributes.name;
      if (!acc.includes(customer)) {
        acc.push(customer);
      }
      return acc;
    }, []);

    usersInfo = usersData.reduce((acc, curr) => {
      const userName = curr.name;
      const userId = curr.id;
      const userObj = { id: userId, name: userName };
      if (!acc.some((obj) => obj.id === userId)) {
        acc.push(userObj);
      }
      return acc;
    }, []);
  } catch {
    return <ErrorPage />;
  }

  const filteredBookings = newBookings.filter((booking) => {
    const employeeMatch =
      !selectedEmployee ||
      selectedEmployee === 'All' ||
      booking.employee === selectedEmployee;
    const serviceMatch =
      !selectedService ||
      selectedService === 'All' ||
      booking.service === selectedService;
    const subServiceMatch =
      !selectedSubService ||
      selectedSubService === 'All' ||
      booking.subservice === selectedSubService;
    const customerNameMatch =
      !selectedCustomerName ||
      selectedCustomerName === 'All' ||
      booking.customer === selectedCustomerName;
    return (
      employeeMatch && serviceMatch && subServiceMatch && customerNameMatch
    );
  });

  const handleEmployeeSelect = (eventKey, event) => {
    setSelectedEmployee(eventKey);
  };

  const handleServiceSelect = (eventKey, event) => {
    setSelectedService(eventKey);
  };

  const handleSubServiceSelect = (eventKey, event) => {
    setSelectedSubService(eventKey);
  };

  const handleCustomerNameSelect = (eventKey, event) => {
    setSelectedCustomerName(eventKey);
  };

  const handleSelectedEvent = (event) => {
    setSelectedEvent(event);
    setModalState(true);
  };

  const handleCreateButtonSelected = () => {
    setSelectedCreateMode(true);
    setModalStateCreate(true);
  };

  const Modal = () => {
    const [formData, setFormData] = useState({
      customerID: selectedEvent.customerId,
      customerName: selectedEvent.customer,
      customerPhone: selectedEvent.phone,
      customerEmail: selectedEvent.email,
      employeeId: selectedEvent.employeeId,
      serviceId: selectedEvent.serviceId,
      bookingDate: new Date(selectedEvent.start),
      subServiceId: selectedEvent.subserviceId,
    });

    const changeHandler = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const HandleDelete = () => {
      const confirm = window.confirm(
        'Are you sure you want to delete this item ?'
      );
      if (confirm) {
        deleteBooking(selectedEvent.id);
      }
      setModalState(false);
    };

    const handleUpdate = () => {
      const confirm = window.confirm(
        'Are you sure you want to update this item ?'
      );
      if (confirm) {
        console.log(parseInt(formData.customerID));
        updateBooking(
          selectedEvent.id,
          parseInt(formData.customerID),
          parseInt(formData.employeeId),
          parseInt(formData.serviceId),
          formData.bookingDate,
          parseInt(formData.subServiceId)
        );
      }
      setModalState(false);
    };

    const handleClose = () => {
      setModalState(false);
    };

    var formGrouWrapper = {
      marginTop: 20,
      marginBottom: 20,
    };

    return (
      <div className={`modal-${modalState ? 'show' : 'hide'}`}>
        <div className={`popup-${modalState ? 'show' : 'hide'}`}>
          <button className="close-btn" onClick={handleClose}>
            <GrClose style={{ width: 30, height: 30 }} />
          </button>
          <div style={{ fontWeight: 'bold', fontSize: 30 }}>
            Edit Booking ID#{selectedEvent.id}
          </div>
          <form onSubmit={handleUpdate}>
            <div className="row">
              <div className="col">
                <div className="form-group text-start" style={formGrouWrapper}>
                  <label htmlFor="employee">Select an employee:</label>
                  <select
                    className="form-select"
                    id="employeeId"
                    name="employeeId"
                    defaultValue={formData.employeeId}
                    onChange={changeHandler}
                    required
                  >
                    <option value="">Select an employee</option>
                    {employeesInfo.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group text-start" style={formGrouWrapper}>
                  <label htmlFor="service">Select a service:</label>
                  <select
                    className="form-select"
                    id="serviceId"
                    name="serviceId"
                    defaultValue={formData.serviceId}
                    onChange={changeHandler}
                    required
                  >
                    <option value="">Select a service</option>
                    {servicesInfo.map((service) => (
                      <option key={service.id} value={service.id}>
                        {service.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group text-start" style={formGrouWrapper}>
                  <label htmlFor="subservice">Select a subservice:</label>
                  <select
                    className="form-select"
                    id="subServiceId"
                    name="subServiceId"
                    defaultValue={formData.subServiceId}
                    onChange={changeHandler}
                    required
                  >
                    <option value="">Select a subservice</option>
                    {subServicesInfo.map((subservice) => (
                      <option key={subservice.id} value={subservice.id}>
                        {subservice.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group text-start" style={formGrouWrapper}>
                  <label>Booking Date</label>
                  <DatePicker
                    selected={formData.bookingDate}
                    onChange={(date) => {
                      setFormData({
                        ...formData,
                        bookingDate: date,
                      });
                    }}
                    timeIntervals={60}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>

                <div className="form-group text-start" style={formGrouWrapper}>
                  <label>Customer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="customerName"
                    name="customerName"
                    defaultValue={formData.customerName}
                    onChange={changeHandler}
                    disabled
                  />
                </div>
                <div className="form-group text-start" style={formGrouWrapper}>
                  <label>Customer Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    id="customerPhone"
                    name="customerPhone"
                    defaultValue={formData.customerPhone}
                    onChange={changeHandler}
                    disabled
                  />
                </div>
                <div className="form-group text-start" style={formGrouWrapper}>
                  <label>Customer Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="customerEmail"
                    name="customerEmail"
                    defaultValue={formData.customerEmail}
                    onChange={changeHandler}
                    disabled
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              style={{ marginRight: 15 }}
              className="btn btn-warning"
            >
              Update
            </button>
            <button
              style={{ marginRight: 15 }}
              className="btn btn-danger"
              onClick={HandleDelete}
            >
              Delete
            </button>
          </form>
        </div>
      </div>
    );
  };

  const ModalCreate = () => {
    const [formData, setFormData] = useState({
      userID: '',
      employeeId: '',
      serviceId: '',
      bookingDate: new Date(),
      subServiceId: '',
    });
    const changeHandler = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    console.log(formData);

    const handleCreate = () => {
      const confirm = window.confirm(
        'Are you sure you want to create this item ?'
      );
      if (confirm) {
        createBooking(
          formData.userID,
          parseInt(formData.employeeId),
          parseInt(formData.serviceId),
          formData.bookingDate,
          parseInt(formData.subServiceId)
        );
      }

      setModalStateCreate(false);
    };

    const handleClose = () => {
      setModalStateCreate(false);
    };

    var formGrouWrapper = {
      marginTop: 20,
      marginBottom: 20,
    };

    return (
      <div className={`modal-${modalStateCreate ? 'show' : 'hide'}`}>
        <div className={`popup-${modalStateCreate ? 'show' : 'hide'}`}>
          <button className="close-btn" onClick={handleClose}>
            <GrClose style={{ width: 30, height: 30 }} />
          </button>
          <div style={{ fontWeight: 'bold', fontSize: 30 }}>
            Add a new Booking
          </div>
          <form onSubmit={handleCreate}>
            <div className="row">
              <div className="col">
                <div className="form-group text-start" style={formGrouWrapper}>
                  <label htmlFor="employee">Select an employee:</label>
                  <select
                    className="form-select"
                    id="employeeId"
                    name="employeeId"
                    defaultValue={formData.employeeId}
                    onChange={changeHandler}
                    required
                  >
                    <option value="">Select an employee</option>
                    {employeesInfo.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group text-start" style={formGrouWrapper}>
                  <label htmlFor="service">Select a service:</label>
                  <select
                    className="form-select"
                    id="serviceId"
                    name="serviceId"
                    defaultValue={formData.serviceId}
                    onChange={changeHandler}
                    required
                  >
                    <option value="">Select a service</option>
                    {servicesInfo.map((service) => (
                      <option key={service.id} value={service.id}>
                        {service.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group text-start" style={formGrouWrapper}>
                  <label htmlFor="subservice">Select a subservice:</label>
                  <select
                    className="form-select"
                    id="subServiceId"
                    name="subServiceId"
                    defaultValue={formData.subServiceId}
                    onChange={changeHandler}
                    required
                  >
                    <option value="">Select a subservice</option>
                    {subServicesInfo.map((subservice) => (
                      <option key={subservice.id} value={subservice.id}>
                        {subservice.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group text-start" style={formGrouWrapper}>
                  <label>Booking Date</label>
                  <DatePicker
                    selected={formData.bookingDate}
                    onChange={(date) => {
                      setFormData({
                        ...formData,
                        bookingDate: date,
                      });
                    }}
                    timeIntervals={60}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
                <div className="form-group text-start" style={formGrouWrapper}>
                  <label htmlFor="subservice">Select a customer:</label>
                  <select
                    className="form-select"
                    id="userID"
                    name="userID"
                    defaultValue={formData.subServiceId}
                    onChange={changeHandler}
                    required
                  >
                    <option value="">Select a customer</option>
                    {usersInfo.map((customer) => (
                      <option key={customer.id} value={customer.id}>
                        {customer.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <button
              type="submit"
              style={{ marginRight: 15 }}
              className="btn btn-primary"
            >
              Create
            </button>
          </form>
        </div>
      </div>
    );
  };

  var desktopDisplay = {
    display: 'block',
  };
  var mobileDisplay = {
    display: 'none',
    minHeight: '100vh',
  };
  return (
    <div>
      <div style={currentWindow >= 950 ? desktopDisplay : mobileDisplay}>
        <div style={{ backgroundColor: '#fec122', width: '100vw' }}>
          <div
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 10,
              backgroundColor: '#fec122',
            }}
          >
            <Link
              to="/admin-home"
              className="d-flex justify-content-start"
              style={{ width: '4vh', height: '3vw' }}
            >
              <IoMdArrowRoundBack
                style={{ width: 40, height: 40, color: 'black' }}
              />
            </Link>
          </div>
          <div className="row" style={{ paddingBottom: 50 }}>
            <div className="col">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleCreateButtonSelected}
              >
                New Booking
              </button>
            </div>
            <div className="col">
              <Dropdown onSelect={handleEmployeeSelect}>
                <Dropdown.Toggle variant="light">
                  {selectedEmployee || 'Filter Employee'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="All">All</Dropdown.Item>
                  {employees.map((employee) => (
                    <Dropdown.Item key={employee} eventKey={employee}>
                      {employee}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col">
              <Dropdown onSelect={handleServiceSelect}>
                <Dropdown.Toggle variant="light">
                  {selectedService || 'Filter Category'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="All">All</Dropdown.Item>
                  {services.map((service) => (
                    <Dropdown.Item key={service} eventKey={service}>
                      {service}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col">
              <Dropdown onSelect={handleSubServiceSelect}>
                <Dropdown.Toggle variant="light">
                  {selectedSubService || 'Filter Service'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="All">All</Dropdown.Item>
                  {subservices.map((subservice) => (
                    <Dropdown.Item key={subservice} eventKey={subservice}>
                      {subservice}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col">
              <Dropdown onSelect={handleCustomerNameSelect}>
                <Dropdown.Toggle variant="light">
                  {selectedCustomerName || 'Filter Customer'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="All">All</Dropdown.Item>
                  {customers.map((customer) => (
                    <Dropdown.Item key={customer} eventKey={customer}>
                      {customer}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col">
              <div className="row">
                <div className="col text-light" style={{ fontWeight: 'bold' }}>
                  <span
                    className="bg rounded-pill bg-dark"
                    style={{ padding: 8 }}
                  >
                    {filteredBookings.length === 0
                      ? 'Filter something'
                      : 'Bookings: ' + filteredBookings.length}{' '}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer limit={1} />
        {selectedEvent && <Modal />}
        {selectedCreateMode && <ModalCreate />}
        <div style={{ backgroundColor: '#fec122' }}>
          <RefreshButton />
        </div>

        <Calendar
          localizer={localizer}
          events={filteredBookings}
          startAccessor="start"
          endAccessor="end"
          style={{ height: '100vh', margin: 'auto' }}
          //onSelectSlot={(e) => handleSelect(e)}
          onSelectEvent={(e) => handleSelectedEvent(e)}
        />
      </div>
      <div style={currentWindow >= 720 ? mobileDisplay : desktopDisplay}>
        <MainBookingsMobile />
      </div>
    </div>
  );
};

export default Bookings;

//
// END: Return element from Bookings component
//
