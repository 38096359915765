import React, { useState } from 'react';
import '../MainEmployees/style.css';
import Loader from '../../Homepage/Loading';
import ErrorPage from '../../Homepage/ErrorPage';
import Header from '../../Homepage/Header/index';
import Footer from '../../Homepage/Footer/index';
import { Link } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import useEmployees from '../../../hooks/actions/employees/get';
import { updateEmployee } from '../../../hooks/actions/employees/update';
import { deleteEmployee } from '../../../hooks/actions/employees/delete';
import { createEmployee } from '../../../hooks/actions/employees/create';
import ModalEmployees from './MainEmployeesModal';
import { ToastContainer } from 'react-toastify';
import RefreshButton from '../RefreshButton';
import ModalAvailabilities from './MainAvailabilitiesModal';

function MainEmployees() {
  const currentWindow = window.innerWidth;
  const { isEmployeeLoading, hasEmployeeError, employeeData } = useEmployees();
  const [modalState, setModalState] = useState(false);
  const [modalAvailabilities, setModalAvailabilities] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [modalStateCreate, setModalStateCreate] = useState(false);

  //API status messages
  if (isEmployeeLoading) {
    return <Loader />;
  } else if (hasEmployeeError) {
    return <ErrorPage />;
  } else if (employeeData.length === 0) {
    return <ErrorPage />;
  }

  var backgroundSettings = {
    backgroundImage:
      'linear-gradient(to top, #fdd570, #fdce61, #fcc752, #fcc042, #fcb931)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    /* end body required properties */
    margin: 'auto',
  };
  var mainFrame = {
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 20,
    width: '70%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '12vh',
    marginBottom: '20vh',
    flexGrow: 1,
    minHeight: '50vh',
  };
  var mainFrameResp = {
    backgroundColor: 'white',
    paddingTop: 20,
    borderRadius: 20,
    width: '95%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 30,
    marginBottom: '3vh',
    flexGrow: 1,
    minHeight: '50vh',
  };
  var mainFrameWrapper = {
    minHeight: '10vh',
  };
  var actionButtons = {
    marginLeft: 5,
    marginRight: 5,
  };

  const handleSelectedEmployee = (record) => {
    setSelectedEmployee(record);
    setModalState(true);
  };

  const handleAvailability = (record) => {
    setSelectedEmployee(record);
    setModalAvailabilities(true);
  };

  const handleCreateButtonSelected = () => {
    setModalStateCreate(true);
  };

  return (
    <div style={backgroundSettings}>
      <Header />
      <ToastContainer />
      {selectedEmployee && (
        <ModalEmployees
          modalState={modalState}
          handleClose={() => {
            setModalState(false);
            setSelectedEmployee(null);
          }}
          handleSave={(id, formData) =>
            updateEmployee(id, formData.name, formData.email)
          }
          handleDelete={() => deleteEmployee(selectedEmployee?.id)}
          selectedEmployee={selectedEmployee}
          isCreateModal={false}
        />
      )}

      <ModalEmployees
        modalState={modalStateCreate}
        handleClose={() => setModalStateCreate(false)}
        handleSave={(formData) => createEmployee(formData.name, formData.email)}
        isCreateModal={true}
      />

      {selectedEmployee && (
        <ModalAvailabilities
          modalState={modalAvailabilities}
          handleClose={() => {
            setModalAvailabilities(false);
            setSelectedEmployee(null);
          }}
          selectedEmployee={selectedEmployee}
        />
      )}
      <div style={mainFrameWrapper}>
        <div style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}>
          <Link
            to="/admin-settings"
            className="d-flex justify-content-start"
            style={{ width: '4vh', height: '3vw' }}
          >
            <IoMdArrowRoundBack
              style={{ width: 40, height: 40, color: 'black' }}
            />
          </Link>
        </div>
        <div style={currentWindow >= 720 ? mainFrame : mainFrameResp}>
          <h3>Employee Management</h3>
          <div className="crud-table">
            <div className="row">
              <div className="col text-start">
                <RefreshButton />
              </div>
              <div className="col text-end">
                <button
                  className="btn btn-success"
                  style={{ width: '10em' }}
                  onClick={handleCreateButtonSelected}
                >
                  New Employee
                </button>
              </div>
            </div>
            {hasEmployeeError && (
              <div className="error">Error: {hasEmployeeError.message}</div>
            )}
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col" className="text-center text-light">
                    ID
                  </th>
                  <th scope="col" className="text-center text-light">
                    Name
                  </th>
                  <th scope="col" className="text-center text-light">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {employeeData &&
                  employeeData.data.map((record) => (
                    <tr key={record.id}>
                      <td className="text-center">{record.id}</td>
                      <td>{record.attributes.fullname}</td>
                      <td className="text-center">
                        <button
                          style={actionButtons}
                          className="btn btn-primary"
                          onClick={() => {
                            handleSelectedEmployee(record);
                          }}
                        >
                          Details
                        </button>
                        <button
                          style={actionButtons}
                          className="btn btn-dark"
                          onClick={() => {
                            handleAvailability(record);
                          }}
                        >
                          Availability
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MainEmployees;
