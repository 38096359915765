import React, { useState } from 'react';
import '../Main/index.css';
import { FaCalendarAlt } from 'react-icons/fa';
import { FaUserClock } from 'react-icons/fa';
import { MdAddCircle } from 'react-icons/md';
import { IoMdSettings } from 'react-icons/io';
import { RiLogoutBoxRFill } from 'react-icons/ri';
import Header from '../..//Homepage/Header/index';
import Footer from '../../Homepage/Footer/index';
import { BiCategoryAlt } from 'react-icons/bi';
import { BsFillBriefcaseFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';

const MainSettingsPage = () => {
  const currentWindow = window.innerWidth;
  const userJson = localStorage.getItem('user');
  let user;

  if (userJson) {
    try {
      user = JSON.parse(userJson);
    } catch (error) {
      console.error('Error parsing user JSON:', error);
    }
  }

  var mainFrame = {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 20,
    width: '80%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 200,
    marginBottom: 200,
    flexGrow: 1,
  };
  var mainFrameResp = {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 20,
    width: '80%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 20,
    marginBottom: 20,
    flexGrow: 1,
  };
  var backgroundSettings = {
    backgroundImage:
      'linear-gradient(to top, #fdd570, #fdce61, #fcc752, #fcc042, #fcb931)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    /* end body required properties */
    margin: 'auto',
  };
  var mainBox = {
    flexGrow: 1,
  };
  var mainIcons = {
    width: 80,
    height: 80,
    color: 'rgb(6, 74, 114)',
    marginBottom: 15,
  };
  var mainIconsResp = {
    width: 80,
    height: 80,
    color: 'rgb(6, 74, 114)',
    marginBottom: 15,
    marginLeft: 30,
    marginRight: 30,
  };
  var mainIconsTitle = {
    fontWeight: 'bold',
    marginBottom: 60,
    marginTop: 10,
    color: 'black',
  };
  var mainIconsTitleResp = {
    fontWeight: 'bold',
    marginBottom: 25,
    marginTop: 10,
    color: 'black',
  };
  var titleDecorationRemove = {
    textDecoration: 'none',
  };

  return (
    <div style={mainBox}>
      <div style={backgroundSettings}>
        <Header />
        <div
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            backgroundColor: '#fcbe3d',
          }}
        >
          <Link
            to="/admin-home"
            className="d-flex justify-content-start"
            style={{ width: '4vh', height: '3vw' }}
          >
            <IoMdArrowRoundBack
              style={{ width: 40, height: 40, color: 'black' }}
            />
          </Link>
        </div>
        <div style={currentWindow >= 720 ? mainFrame : mainFrameResp}>
          <h3 style={{ paddingBottom: 40 }}>
            Welcome {user ? user.username : ''}!
          </h3>
          <div className="row">
            <div className="col">
              <a style={titleDecorationRemove} href="/admin-register">
                <div>
                  <MdAddCircle
                    className="animatedIcons"
                    style={currentWindow >= 720 ? mainIcons : mainIconsResp}
                  />
                  <div
                    style={
                      currentWindow >= 720 ? mainIconsTitle : mainIconsTitleResp
                    }
                  >
                    Add User
                  </div>
                </div>
              </a>
            </div>
            <div className="col">
              <a style={titleDecorationRemove} href="/admin-employees">
                <div>
                  <FaUserClock
                    className="animatedIcons"
                    style={currentWindow >= 720 ? mainIcons : mainIconsResp}
                  />
                  <div
                    style={
                      currentWindow >= 720 ? mainIconsTitle : mainIconsTitleResp
                    }
                  >
                    Employees
                  </div>
                </div>
              </a>
            </div>
            <div className="col">
              <a style={titleDecorationRemove} href="/admin-categories">
                <div>
                  <BiCategoryAlt
                    className="animatedIcons"
                    style={currentWindow >= 720 ? mainIcons : mainIconsResp}
                  />
                  <div
                    style={
                      currentWindow >= 720 ? mainIconsTitle : mainIconsTitleResp
                    }
                  >
                    Categories
                  </div>
                </div>
              </a>
            </div>
            <div className="col">
              <a style={titleDecorationRemove} href="/admin-services">
                <div>
                  <BsFillBriefcaseFill
                    className="animatedIcons"
                    style={currentWindow >= 720 ? mainIcons : mainIconsResp}
                  />
                  <div
                    style={
                      currentWindow >= 720 ? mainIconsTitle : mainIconsTitleResp
                    }
                  >
                    Services
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MainSettingsPage;
