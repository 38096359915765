import React, { useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormContext from '../../../FormContext/form.context';
import ErrorPage from '../../ErrorPage';
import moment from "moment-timezone"
import { createBooking } from '../../../../hooks/actions/bookings/create';
import { fetchUserData } from '../../../../hooks/actions/authentication/user';
import Loader from '../../Loading';
import { toast, ToastContainer } from 'react-toastify';



const MainConfirmation = () => {
  const currentWindow = window.innerWidth;
    //API variables
  const { service, serviceId, subService, subServiceId, userData, setUserData,
      staff, staffId, dateFixed} = useContext(FormContext);
      const [loading, setLoading] = useState(false);
      const [error, setError] = useState(false);
  

    //States
  const [modifiedData, setModifiedData] = useState({ customername: "", customerphone: "", customeremail: "", service: "", subservice: "", staff: "", date: new Date() });
    //Stylling
  var formStyle={
    paddingTop: 20,
    paddingLeft: 100,
    paddingRight: 100,
  }
  var formStyleResp={
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  }
  var formLabelStyle={
    textAlign: 'start'
  }
  var buttonStyle={
    width: '15vw',
    height: '6vh'
  }
  var buttonStyleResp={
    width: '60vw',
    height: '6vh',
    marginBottom: '3vh'
  }
  var buttonArea={
    paddingBottom: 40,
    paddingTop: 20
  }
  var confimationInfoWrapper={
    paddingTop: 20,
    paddingBottom: 1
  }
  var titleStyle={
    fontWeight: 'bold'
  }
  var titleArea={
    width: '100%',
    margin: 'auto',
    padding: 10
  }
  var reviewInfoTitle={
    color: 'red',
    fontWeight: 'bold'
  }
  var estStyle={
    color: 'gray'
  } 

  let date = moment(dateFixed); 

  useEffect(() => {
    const fetchData = async () => {
      const userToken = JSON.parse(localStorage.getItem('user')).jwt;
      const data = await fetchUserData(userToken);
      setUserData(data);
    };

    fetchData();
  }, []);


  //Post Rules
  modifiedData.service = serviceId;
  modifiedData.staff = staffId;
  modifiedData.date = dateFixed;
  modifiedData.subservice = subServiceId;

  const changeHandler = e => {
    setModifiedData({...modifiedData, [e.target.name]: e.target.value})
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true
  
    try {
      await createBooking(
        parseInt(userData.id),
        parseInt(modifiedData.staff),
        parseInt(modifiedData.service),
        modifiedData.date,
        parseInt(modifiedData.subservice)
      );
      const nextPage = page + 1;
      setPage(nextPage);
    } catch (error) {
      console.log(error)
    }
  
    setLoading(false); // Set loading to false after the function completes
  }
  //To allow inline back button and 
  const { page, setPage } = useContext(FormContext);

    return (
      <>
      {loading ? <Loader /> :  
        <>
        <ToastContainer limit={1}/>
          <div style={confimationInfoWrapper} className='bg bg-light'>
          <p style={reviewInfoTitle}>Review your booking information</p>
          <p style={titleArea}><span style={titleStyle}>Staff: &nbsp;</span><span><span>{staff}</span></span></p>
          <p style={titleArea}><span style={titleStyle}>Category/Service: &nbsp;</span><span>{service} / {subService}</span></p>
          <p style={titleArea}><span style={titleStyle}>Date: &nbsp;</span><span>{date.format('YYYY-MM-DD')}</span></p>
          <p style={titleArea}><span style={titleStyle}>Time: &nbsp;</span><span>{date.format('HH:mm:ss')}<span style={estStyle}> - Eastern Time Zone</span></span></p>
          <br/>
        </div>

        <Form style={currentWindow >= 720 ? formStyle : formStyleResp} onSubmit={handleSubmit}>
        <p>Your account information</p>
          <Form.Group className="mb-3" controlId="service">
            <Form.Control type="text" required name="service" defaultValue={modifiedData.service} onChange={changeHandler} hidden/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="staff">
            <Form.Control type="text"required name="employee" defaultValue={modifiedData.staff} onChange={changeHandler} hidden/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="staff">
            <Form.Control type="text"required name="bookingdate" defaultValue={modifiedData.date} onChange={changeHandler} hidden/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="customername" style={formLabelStyle}>
            <Form.Label>Full name</Form.Label>
            <Form.Control type="text" name="customername" required defaultValue={userData?.name} disabled/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="customeremail" style={formLabelStyle}>
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" name='customeremail' required defaultValue={userData?.email} disabled/>
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="customerphone" style={formLabelStyle}>
            <Form.Label>Phone</Form.Label>
            <Form.Control type="text" required name="customerphone" pattern="^(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?$" defaultValue={userData?.phone} disabled />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>
          <div style={buttonArea}>
            <button className="btn btn-secondary mx-2 shadow" onClick={() => {
                      const nextPage = page - 1;
                      setPage(nextPage);
                    }} style={currentWindow >= 720 ? buttonStyle : buttonStyleResp}> 
                    Back 
            </button>
            <Button className="btn btn-primary mx-2 shadow" type="submit" style={currentWindow >= 720 ? buttonStyle : buttonStyleResp} onClick={() => {

                    }}>
              Confirm
            </Button>
          </div>
        </Form>
        </>     
      }
        
      </>

    );
};
export default MainConfirmation;