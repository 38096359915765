import { toast } from 'react-toastify';
import axiosInstance from '../../axios';

export const deleteEmployee = (id) => {
  axiosInstance
    .delete('api/employees/' + id)
    .then((res) =>
      toast.success(`Employee ${id} deleted successfully`, {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
      })
    )
    .catch((err) =>
      toast.error(`Failed to delete employee ${id}`, {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
      })
    );
};
