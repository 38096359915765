import { storeUser } from '../../../components/AdminPage/helpers';
import axiosInstance from '../../axios';
import { fetchUserData } from './user';

const handleLogin = async (user, navigate, setUser, toast) => {
  try {
    if (user.identifier && user.password) {
      const { data } = await axiosInstance
        .post('api/auth/local', user)
        .then((res) => {
          return res;
        })
        .catch((err) =>
          toast.error(
            `Failed to login. The entered email or password is incorrect.`,
            {
              autoClose: 2000,
              position: toast.POSITION.TOP_RIGHT,
            }
          )
        );
      if (data.jwt) {
        storeUser(data);
        setUser({ password: '', identifier: '' });
        const userData = await fetchUserData(data.jwt);
        if (userData.role.type === 'customer') {
          navigate('/');
          window.location.reload();
        } else if (userData.role.type === 'admin') {
          navigate('/admin-home');
          window.location.reload();
        }
      }
    } else {
      toast.error(`Failed to login. Please Input Email and Password !`, {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {}
};

export default handleLogin;
