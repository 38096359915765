import { toast } from 'react-toastify';
import axiosInstance from '../../axios';

export const deleteBooking = (id) => {
  axiosInstance
    .delete('api/bookings/' + id)
    .then((res) =>
      toast.success(`Booking ${id} deleted successfully`, {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
      })
    )
    .catch((err) =>
      toast.error(`Failed to delete booking ${id}`, {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
      })
    );
};
