import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom/dist';
import HomePage from './components/Homepage';
import Login from './components/AdminPage/Login';
import AdminPage from './components/AdminPage/Main';
import Logout from './components/AdminPage/Logout';
import { Protector } from './components/AdminPage/helpers';
import Bookings from './components/AdminPage/MainBookings';
import MainEmployees from './components/AdminPage/MainEmployees';
import MainServices from './components/AdminPage/MainServices';
import MainSubServices from './components/AdminPage/MainSubServices';
import NotFoundComponent from './components/404/404';
import ForgotPassword from './components/AdminPage/ForgotPassword';
import ResetPassword from './components/AdminPage/ResetPassword';
import MainSettingsPage from './components/AdminPage/MainSettings';
import RegistrationForm from './components/AdminPage/Registration/RegistrationForm';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <Protector Component={HomePage} roles={['admin', 'customer']} />
          }
        />
        <Route path="/login" exact element={<Login />} />
        <Route path="/logout" exact element={<Logout />} />
        <Route path="/forgot-password" exact element={<ForgotPassword />} />
        <Route path="/reset-password" exact element={<ResetPassword />} />
        <Route
          path="/admin-home"
          element={<Protector Component={AdminPage} roles={['admin']} />}
        />
        <Route
          path="/admin-bookings"
          element={<Protector Component={Bookings} roles={['admin']} />}
        />
        <Route
          path="/admin-employees"
          element={<Protector Component={MainEmployees} roles={['admin']} />}
        />
        <Route
          path="/admin-categories"
          element={<Protector Component={MainServices} roles={['admin']} />}
        />
        <Route
          path="/admin-services"
          element={<Protector Component={MainSubServices} roles={['admin']} />}
        />
        <Route
          path="/admin-register"
          element={<Protector Component={RegistrationForm} roles={['admin']} />}
        />
        <Route
          path="/admin-settings"
          element={<Protector Component={MainSettingsPage} roles={['admin']} />}
        />
        <Route path="*" element={<NotFoundComponent />} />
      </Routes>
    </div>
  );
}

export default App;
