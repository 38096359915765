import React, { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { toast } from 'react-toastify';

export default function ModalEmployees({
  modalState,
  handleClose,
  handleSave,
  handleDelete,
  selectedEmployee,
  isCreateModal,
}) {
  const [formData, setFormData] = useState({
    name: isCreateModal ? null : selectedEmployee.attributes.fullname,
    email: isCreateModal ? null : selectedEmployee.attributes.email,
  });
  const [isDelete, setIsDelete] = useState(false);
  const currentWindow = window.innerWidth;

  var formGrouWrapper = {
    marginTop: 20,
    marginBottom: 20,
  };
  var modalResp={
    width: '95vw'
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isDelete) {
      if (selectedEmployee.attributes.bookings.data.length > 0) {
        toast.error(`You cannot delete this employee. Please contact admin.`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        handleDelete(selectedEmployee.id);
      }
    } else {
      if (isCreateModal) {
        handleSave(formData);
      } else {
        handleSave(selectedEmployee.id, formData);
      }
      handleClose();
    }
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className={`modal-${modalState ? 'show' : 'hide'}`}>
      <div className={`popup-${modalState ? 'show' : 'hide'}`} style={currentWindow >= 720 ? null : modalResp}>
        <button className="close-btn" onClick={handleClose}>
          <GrClose style={{ width: 30, height: 30 }} />
        </button>
        <div style={{ fontWeight: 'bold', fontSize: 30 }}>
          {isCreateModal
            ? 'Create Employee'
            : `Edit Employee ID#${selectedEmployee.id}`}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col">
              <div className="form-group text-start" style={formGrouWrapper}>
                <label>Employee Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  defaultValue={
                    isCreateModal ? '' : selectedEmployee.attributes.fullname
                  }
                  onChange={changeHandler}
                  required
                />
              </div>
              <div className="form-group text-start" style={formGrouWrapper}>
                <label>Employee Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  defaultValue={
                    isCreateModal ? '' : selectedEmployee.attributes.email
                  }
                  onChange={changeHandler}
                  required
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            style={{ marginRight: 15 }}
            className="btn btn-warning"
          >
            {isCreateModal ? 'Create' : 'Update'}
          </button>
          {!isCreateModal && (
            <button
              type="submit"
              style={{ marginRight: 15 }}
              className="btn btn-danger"
              onClick={() => {
                setIsDelete(true);
              }}
            >
              Delete
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
