import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { ToastContainer } from 'react-toastify';
import { createUser } from '../../../../hooks/actions/authentication/register';
import { Link } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import Header from '../../../Homepage/Header';
import Footer from '../../../Homepage/Footer';

function RegistrationForm() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const currentWindow = window.innerWidth;

  var mainFrame = {
    backgroundColor: 'white',
    padding: 50,
    borderRadius: 20,
    width: '30%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 200,
    marginBottom: 200,
    flexGrow: 1,
  };
  var mainFrameResp = {
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 20,
    width: '95%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 20,
    marginBottom: 20,
    flexGrow: 1,
  };
  var buttonStyle = {
    marginTop: 20,
    marginBottom: 20,
    width: '10vw',
    height: '7vh',
  };
  var buttonStyleResp = {
    marginTop: 20,
    marginBottom: 20,
    width: '50vw',
    height: '7vh',
  };
  var backgroundSettings = {
    backgroundImage:
      'linear-gradient(to top, #fdd570, #fdce61, #fcc752, #fcc042, #fcb931)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    /* end body required properties */
    margin: 'auto',
  };
  var mainBox = {
    flexGrow: 1,
  };
  var loginTitle = {
    marginBottom: 30,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(username, name, email, phone);
    const response = await createUser(username, name, email, phone);
    console.log(response.data);

    // Reset input fields after successful form submission
    setUsername('');
    setEmail('');
    setName('');
    setPhone('');
  };

  return (
    <div style={mainBox}>
      <div style={backgroundSettings}>
        <Header />
        <div
          style={{
            paddingTop: 10,
            paddingBottom: 20,
            paddingLeft: 10,
            backgroundColor: '#fcbe3d',
          }}
        >
          <Link
            to="/admin-home"
            className="d-flex justify-content-start"
            style={{ width: '4vh', height: '3vw' }}
          >
            <IoMdArrowRoundBack
              style={{ width: 40, height: 40, color: 'black' }}
            />
          </Link>
        </div>
        <ToastContainer />
        <div
          className="shadow"
          style={currentWindow >= 720 ? mainFrame : mainFrameResp}
        >
          <h2 style={loginTitle}>Create a customer user</h2>
          <Form>
            <Form.Group className="mb-3" controlId="username">
              <Form.Label className="d-flex justify-content-start">
                Username
              </Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter customer username"
                required
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label className="d-flex justify-content-start">
                Name
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter customer name"
                required
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="phone">
              <Form.Label className="d-flex justify-content-start">
                Phone
              </Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter customer phone"
                required
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label className="d-flex justify-content-start">
                Email
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter customer email"
                required
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            <Button
              style={currentWindow >= 720 ? buttonStyle : buttonStyleResp}
              className="btn btn-primary btn- btn-block shadow"
              onClick={handleSubmit}
            >
              Register
            </Button>
          </Form>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default RegistrationForm;
