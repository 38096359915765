import axios from 'axios';

const baseURL = process.env.REACT_APP_BACKEND_URL;

let headers = {};

if (localStorage.getItem('user')) {
  headers.Authorization = `Bearer ${
    JSON.parse(localStorage.getItem('user')).jwt
  }`;
}

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers,
});

export default axiosInstance;
