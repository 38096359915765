import { useState, createContext } from 'react';

const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [page, setPage] = useState(1);
  const [service, setService] = useState('');
  const [serviceId, setServiceId] = useState();
  const [subService, setSubService] = useState('');
  const [subServiceId, setSubServiceId] = useState();
  const [dateSelected, setDateSelected] = useState();
  const [timeSelected, setTimeSelected] = useState();
  const [dateFixed, setDateFixed] = useState(null);
  const [staff, setStaff] = useState('');
  const [staffId, setStaffId] = useState();
  const [userData, setUserData] = useState();
  return (
    <FormContext.Provider
      value={{
        service,
        serviceId,
        subService,
        subServiceId,
        staff,
        staffId,
        dateSelected,
        timeSelected,
        dateFixed,
        userData,
        page,
        setService,
        setServiceId,
        setSubService,
        setSubServiceId,
        setStaff,
        setStaffId,
        setDateSelected,
        setTimeSelected,
        setDateFixed,
        setUserData,
        setPage,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
