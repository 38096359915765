import { toast } from 'react-toastify';
import axiosInstance from '../../axios';

export const updateEmployee = async (id, name, email) => {
  try {
    const response = await axiosInstance.put('api/employees/' + id, {
      data: {
        fullname: name,
        email: email,
      },
    });
    toast.success(`Employee updated successfully`, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    toast.error(`Failed to update employee ${error}`, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};
