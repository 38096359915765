import { toast } from 'react-toastify';
import axiosInstance from '../../axios';

export const updateSubService = async (id, name, description, servicesId) => {
  try {
    const response = await axiosInstance.put('api/subservices/' + id, {
      data: {
        name: name,
        description: description,
        services: servicesId,
      },
    });
    toast.success(`Service updated successfully`, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    toast.error(`Failed to update service ${error}`, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};
