import React, { useContext } from 'react';
import MainServices from '../MainServices';
import FormContext from '../../../FormContext/form.context';
import MainStaff from '../MainStaff';
import MainConfirmation from '../MainConfirmation/index'
import MainPagination from '../MainPagination/index'
import DateTime from '../MainDateTime/DateTime';
import MainConfirmationRedirect from '../MainConfirmationRedirect';
import MainPaginationMobile from '../MainPagination/MainPaginationMobile';

const MainSteps = () => {
  const { page } = useContext(FormContext);
  const currentWindow = window.innerWidth;
  var mainFrame={
    backgroundColor: 'white',
    padding: 0,
    borderRadius: 20,
    width: '70%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 50,
    marginBottom: 50,
    flexGrow: 1
  }
  var mainFrameResp={
    backgroundColor: 'white',
    padding: 0,
    borderRadius: 20,
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 50,
    marginBottom: 50,
    flexGrow: 1,
    minHeight: '60vh'
  }
  var mainBox={
    flexGrow: 1,
    padding: 12
  }
  return (
    <div style={mainBox}>
        <div className='shadow' style={currentWindow >= 720 ? mainFrame : mainFrameResp}>
          <div>

              {currentWindow >= 720 ? <MainPagination /> : <MainPaginationMobile />}
            <div>
              {
                page === 1 ? (
                  <MainServices />
                ) : page === 2 ? (
                  <MainStaff /> //Change page element - Staff
                ) : page === 3 ? (
                  <DateTime /> //Change page element - Date/time
                ) : page === 4 ? (
                  <MainConfirmation /> //Change page element - Confirmation Page
                ) : (
                  <MainConfirmationRedirect />
                ) //Change page element - Main page (Login ?)
              }
            </div>
          </div>
        </div>
    </div>
  );
};

export default MainSteps;
