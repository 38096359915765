import axiosInstance from '../../axios';
import { useEffect, useState } from 'react';

const useSubServices = () => {
  const [subServiceData, setSubServiceData] = useState([]);
  const [hasSubServiceError, setHasSubServiceError] = useState(false);
  const [isSubServiceLoading, setIsSubServiceLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsSubServiceLoading(true);

      try {
        const res = await axiosInstance.get('api/subservices?populate=*');
        const json = await res.data;
        setSubServiceData(json);
        setIsSubServiceLoading(false);
        setHasSubServiceError(false);
      } catch (err) {
        setHasSubServiceError(true);
        setIsSubServiceLoading(false);
      }
    };

    fetchData();
  }, []);

  return { isSubServiceLoading, hasSubServiceError, subServiceData };
};

export default useSubServices;
