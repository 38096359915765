import axiosInstance from '../../axios';
import { useEffect, useState } from 'react';

const useBookings = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axiosInstance.get('api/bookings?populate=*');
        const json = await res.data;
        while (json.meta.pagination.page < json.meta.pagination.pages) {
          const nextPage = await axiosInstance.get(
            `bookings?page=${json.meta.pagination.page + 1}&populate=*`
          );
          json.data = json.data.concat(nextPage.data);
          json.meta = nextPage.meta;
        }
        setData(json);
        setLoading(false);
        setError(false);
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return { loading, error, data };
};

export default useBookings;
