import React, {useEffect} from 'react';
import Icon from "../../../../images/errorIcon.png";
import Header from '../../../Homepage/Header/index';
import Footer from '../../../Homepage/Footer/index';
import { Link } from 'react-router-dom';

const MainBookingsMobile = () => {
  var redirectMessage={
    color: 'gray'
  }
  var confirmationTitle={
    padding: 20
  }
  var imgStyle={
    width: 200,
    height: 230,
    alt: "Creative Connex Icon",
    paddingBottom: 40
  }
  var backgroundSettings={
    minHeight: '80vh',
    paddingTop: '5vh',
    backgroundImage: 'linear-gradient(to top, rgb(253, 213, 112), rgb(253, 206, 97), rgb(252, 199, 82), rgb(252, 192, 66), rgb(252, 185, 49))'
  }
  var buttonArea={
    paddingBottom: 40,
    paddingTop: 20
  } 
  var redirectMessage={
    color: 'gray'
  }
  var counterStyle={
    fontWeight: 'bold',
    color: 'black'
  }
  return (
    <div>
        <Header />
        <div style={backgroundSettings}>
            <div style={{backgroundColor: 'white', width: '90%', margin: 'auto', borderRadius:'20px'}}>
                <h2 style={confirmationTitle}>Desktop only</h2>
                <img src={Icon} style={imgStyle}/>
                <h5 style={{paddingLeft: 10, paddingRight: 10}}>Calendar overview is only for desktop screens</h5>
                <p style={redirectMessage}>Minimum screen width 950px</p>
                <div>
                    <Link to="/admin-home">
                        <button className='btn btn-danger' style={{height: '7vh', width: '30vw'}}>
                            Back
                        </button>  
                    </Link>
                    
                </div>
                <br/>      
            </div>   
        </div>
        <Footer />
    </div>
    
  );
};

export default MainBookingsMobile;