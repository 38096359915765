import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { resetPassword } from '../../../hooks/actions/authentication/reset_password';
import Header from '../../Homepage/Header/index';
import Footer from '../../Homepage/Footer/index';
import Form from 'react-bootstrap/Form';

function ResetPassword() {
  const currentWindow = window.innerWidth;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const code = new URLSearchParams(window.location.search).get('code');
      const response = await resetPassword(code, password, confirmPassword);

      if (response) {
        setSuccess(true);
      }
    } catch (error) {
      console.error(error);
      setError('An error occurred');
    }
  };
  var mainFrame = {
    backgroundColor: 'white',
    padding: 50,
    borderRadius: 20,
    width: '30%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 200,
    marginBottom: 200,
    flexGrow: 1,
  };
  var mainFrameResp = {
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 20,
    width: '95%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 200,
    marginBottom: 250,
    flexGrow: 1,
  };
  var backgroundSettings = {
    backgroundImage:
      'linear-gradient(to top, #fdd570, #fdce61, #fcc752, #fcc042, #fcb931)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    /* end body required properties */
    margin: 'auto',
  };
  var mainBox = {
    flexGrow: 1,
  };
  var buttonStyle = {
    marginTop: '2vh',
  };
  var buttonStyleResp = {
    marginTop: '5vh',
  };

  return (
    <div style={mainBox}>
      <div style={backgroundSettings}>
        <Header />
        <div
          className="shadow"
          style={currentWindow >= 720 ? mainFrame : mainFrameResp}
        >
          <h1 style={{ marginBottom: 30 }}>Reset Password</h1>
          {success ? (
            <p>
              Your password has been reset. Please{' '}
              <Link to="/login">log in</Link> with your new password.
            </p>
          ) : (
            <form onSubmit={handleSubmit}>
              {error && <div className="error">{error}</div>}
              <Form.Group className="mb-3">
                <Form.Label className="d-flex justify-content-start">
                  New Password
                </Form.Label>
                <Form.Control
                  type="password"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Enter your password"
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="d-flex justify-content-start">
                  Confirm Password
                </Form.Label>
                <Form.Control
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  placeholder="Enter your password"
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
              <button
                className="btn btn-primary"
                style={currentWindow >= 720 ? buttonStyle : buttonStyleResp}
                type="submit"
              >
                Reset Password
              </button>
            </form>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ResetPassword;
