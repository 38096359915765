import { toast } from 'react-toastify';
import axiosInstance from '../../axios';

export const resetPassword = async (code, password, confirmedPassword) => {
  try {
    const response = await axiosInstance.post(
      `/api/auth/reset-password?code=${code}`,
      {
        code: code,
        password: password,
        passwordConfirmation: confirmedPassword,
      }
    );
    return response.data;
  } catch (error) {
    toast.error(`Failed to send email. Try again later`, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    });
    return null;
  }
};
