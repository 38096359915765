import axiosInstance from '../../axios';
import { useEffect, useState } from 'react';

const useServices = () => {
  const [serviceData, setServiceData] = useState([]);
  const [hasServiceError, setHasServiceError] = useState(false);
  const [isServiceLoading, setIsServiceLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsServiceLoading(true);

      try {
        const res = await axiosInstance.get('api/services?populate=*');
        const json = await res.data;
        setServiceData(json);
        setIsServiceLoading(false);
        setHasServiceError(false);
      } catch (err) {
        setHasServiceError(true);
        setIsServiceLoading(false);
      }
    };

    fetchData();
  }, []);

  return { isServiceLoading, hasServiceError, serviceData };
};

export default useServices;
