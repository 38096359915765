import { toast } from 'react-toastify';
import axiosInstance from '../../axios';

export const createEmployee = async (name, email) => {
  try {
    const response = await axiosInstance.post('api/employees', {
      data: {
        fullname: name,
        email: email,
      },
    });
    toast.success(`Employee created successfully`, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    toast.error(`Failed to create employee ${error}`, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};
