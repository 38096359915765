import { toast } from 'react-toastify';
import axiosInstance from '../../axios';

export const updateBooking = async (
  id,
  userID,
  employeeID,
  serviceID,
  date,
  subserviceID
) => {
  try {
    const response = await axiosInstance.put('api/bookings/' + id, {
      data: {
        user: userID,
        employee: employeeID,
        service: serviceID,
        subservice: subserviceID,
        bookingdate: date,
      },
    });
    toast.success(`Booking updated successfully`, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    toast.error(`Failed to update booking ${error}`, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};
