import React, { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { toast } from 'react-toastify';

export default function ModalServices({
  modalState,
  handleClose,
  handleSave,
  handleDelete,
  selected,
  isCreateModal,
}) {
  const [formData, setFormData] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const currentWindow = window.innerWidth;

  var formGrouWrapper = {
    marginTop: 20,
    marginBottom: 20,
  };
  console.log(selected);
  var modalResp = {
    width: '95vw',
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isDelete) {
      if (selected.attributes.bookings.data.length > 0) {
        toast.error(`You cannot delete this category. Please contact admin.`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        handleDelete(selected.id);
      }
    } else {
      if (isCreateModal) {
        handleSave(formData);
      } else {
        handleSave(selected.id, formData);
      }
    }
    handleClose();
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className={`modal-${modalState ? 'show' : 'hide'}`}>
      <div
        className={`popup-${modalState ? 'show' : 'hide'}`}
        style={currentWindow >= 720 ? null : modalResp}
      >
        <button className="close-btn" onClick={handleClose}>
          <GrClose style={{ width: 30, height: 30 }} />
        </button>
        <div style={{ fontWeight: 'bold', fontSize: 30 }}>
          {isCreateModal
            ? 'Create Category'
            : `Edit Category ID#${selected.id}`}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col">
              <div className="form-group text-start" style={formGrouWrapper}>
                <label>Category Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  defaultValue={isCreateModal ? '' : selected.attributes.name}
                  onChange={changeHandler}
                  required
                />
              </div>
              <div className="form-group text-start" style={formGrouWrapper}>
                <label>Category Description</label>
                <input
                  type="textarea"
                  className="form-control"
                  id="description"
                  name="description"
                  defaultValue={
                    isCreateModal ? '' : selected.attributes.description
                  }
                  onChange={changeHandler}
                  required
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            style={{ marginRight: 15 }}
            className="btn btn-warning"
          >
            {isCreateModal ? 'Create' : 'Update'}
          </button>
          {!isCreateModal && (
            <button
              type="submit"
              style={{ marginRight: 15 }}
              className="btn btn-danger"
              onClick={() => {
                setIsDelete(true);
              }}
            >
              Delete
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
