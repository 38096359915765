import React, { useContext, useState } from 'react';
import useFetch from '../../../../hooks/useFetch';
import FormContext from '../../../FormContext/form.context';
import Accordion from 'react-bootstrap/Accordion';
import {BsFillBriefcaseFill} from 'react-icons/bs';
import { FaInfoCircle } from 'react-icons/fa';
import ErrorPage from '../../ErrorPage';
import Loader from '../../Loading';
import useServices from '../../../../hooks/actions/serv/get';


const MainServices = () => {
  //API variables
  const { service, setService, setServiceId, subService, setSubService, setSubServiceId, page, setPage } = useContext(FormContext);
  const { isServiceLoading, hasServiceError, serviceData } = useServices()
  const [showDescription, setShowDescription] = useState(false);
  const currentWindow = window.innerWidth;
  
  //API status messages
  if (isServiceLoading) {
    return <Loader />;
  } else if (hasServiceError) {
    return <ErrorPage />;
  } else if (serviceData.length === 0) {
    return <ErrorPage />;
  }

  //Internal functions variables
  var counter = 0;
  //Style variables
  var gridContainerMain = {
    padding: 0,
    borderRadius: 90,
    marginBottom: 20
  };
  var gridItemMain = {
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: 15,
    textAlign: 'start',  
    border: '2px hidden red'
  };
  var itemsArea={
    padding: 0
  }
  var singleItemArea={
    marginLeft: 0,
    marginRight: 0
  }
  var singleItemAreaSelected={
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: 15,
    textAlign: 'start',   
    border: '2px hidden red'
  }
  var staffName={
    paddingLeft: 40
  }
  var accordionInnerArea={
    padding: 0
  }
  var accordionItem={
    borderRadius: 0, 
    borderStyle: 'hidden'
  }
  var accordionItemResp={
    borderRadius: 0, 
    borderStyle: 'hidden',
    padding: 15
  }
  var buttonArea={
    paddingBottom: 40,
    paddingTop: 20
  }
  var buttonStyle={
    width: '15vw',
    height: '6vh'
  }
  var buttonStyleResp={
    width: '60vw',
    height: '6vh'
  }
  var infoStyling={
    color: 'grey',
    fontSize: 14
  }
  var infoStylingResp={
    color: 'grey',
    fontSize: 12
  }
  return (
    <div className='container'  style={itemsArea}>
      <div style={gridContainerMain}>
        <span style={currentWindow >= 720 ? infoStyling : infoStylingResp}>Click on the desired category below, then select your service</span>
        <Accordion>
          {serviceData && serviceData.data.map((service) => (
            <Accordion.Item eventKey={counter} style={currentWindow >= 720 ? accordionItem : accordionItemResp} className='border-top border-bottom' key={service.id} onClick={function () {
              setService(service.attributes.name);
              setServiceId(service.id)
            }}>
            {increaseCounter()}
            <Accordion.Header><BsFillBriefcaseFill style={{marginRight: 10}}/>{service.attributes.name}</Accordion.Header>
            <Accordion.Body style={accordionInnerArea}>
              {service.attributes.subservices.data.map((item) => (
                <div key={item.id} className="row" style={singleItemArea}>
                  <button
                    className={item.attributes.name === subService ? 'border-top border-bottom' : 'bg-white border-top border-bottom'}
                    onClick={function () {
                      setSubService(item.attributes.name);
                      setSubServiceId(item.id)
                      setPage(page + 1);
                    }}
                    style={item.attributes.name === subService ? singleItemAreaSelected : gridItemMain}

                    >
                      
                      <div className='d-sm-inline-block' style={staffName}>
                        {item.attributes.name} <span><FaInfoCircle style={{ cursor: 'default' }}                    onMouseEnter={() => setShowDescription(item.attributes.description)}
                    onMouseLeave={() => setShowDescription("")} /></span>
          
                      </div>
                  </button>

                  {showDescription === item.attributes.description && (
        <div style={{ 
          position: "absolute",
            zIndex: 1,
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#ffffff",
            padding: "10px",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.15)",
            borderRadius: "4px",
            textAlign: "center",
            width: "80%",
            maxWidth: "400px",
        }}>
          {item.attributes.description}
        </div>
      )}
                </div>
              ))}
            </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion> 
      </div> 
      <div style={buttonArea}>
        {page > 1 && page !== 4 && (
          <button className="btn btn-secondary mx-2 shadow" onClick={() => {
            const nextPage = page - 1;
              setPage(nextPage);
          }} style={currentWindow >= 720 ? buttonStyle : buttonStyleResp}> 
            Back 
          </button>
        )}
        {page < 4 && subService !== "" && (
          <button className="btn btn-primary mx-2 shadow" onClick={() => {
            const nextPage = page + 1;
              setPage(nextPage);
          }} style={currentWindow >= 720 ? buttonStyle : buttonStyleResp}>
            Next
          </button>
        )}
      </div>     
    </div>
  );
  //To increase the counter that addresses event key on accordion item
  function increaseCounter(){
    counter++;
  }
};
export default MainServices;
