import { toast } from 'react-toastify';
import axiosInstance from '../../axios';

export const deleteAvailability = (id) => {
  axiosInstance
    .delete('api/availabilities/' + id)
    .then((res) =>
      toast.success(`Availability ID # ${id} was deleted successfully`, {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
      })
    )
    .catch((err) =>
      toast.error(`Failed to delete availability ${id}`, {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
      })
    );
};
