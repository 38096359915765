import React, { useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {add, setHours, setMinutes } from 'date-fns';
import FormContext from '../../../../FormContext/form.context';
import ErrorPage from '../../../ErrorPage';
import Loader from '../../../Loading';
import './datetime.css'
import useEmployees from '../../../../../hooks/actions/employees/get';

const DateTime = () => {
  const currentWindow = window.innerWidth;
  var buttonArea={
    paddingBottom: 40,
    paddingTop: 20
  }
  var buttonStyle={
    width: '15vw',
    height: '6vh'
  }
  var buttonStyleResp={
    width: '60vw',
    height: '6vh',
    marginBottom: '3vh'
  }
  var titleStyle={
    fontWeight: 'bold',
    fontSize: 25,
    paddingBottom: 25
  }
  var spacer={
    paddingBottom: 20
  }
  var estStyle={
    color: 'gray'
  }

  const {
    dateFixed,
    staffId,
    setDateFixed,
    page,
    setPage
  } = useContext(FormContext);
  //Load employee availability
  const { isEmployeeLoading, hasEmployeeError, employeeData } = useEmployees();

  const tomorrow = add(new Date(), { days: 1 });
  const maxDate = add(tomorrow, { months: 1 });
  
  //API status messages
  if (isEmployeeLoading) {
    return <Loader />;
  } else if (hasEmployeeError) {
    return <ErrorPage />;
  } else if (employeeData.length === 0) {
    return <ErrorPage />;
  }

  

  // display only days
  const isWeekDay = (date) => {
    const day = date.getDay(date);
    const employee = employeeData.data.find((employeeId) => employeeId.id === staffId);
    if (employee) {
      const availabilities = employee.attributes.availabilities.data;
      const weekdays = availabilities.map((item) => item.attributes.weekday);
      return day !== 0 && day !== 6 && weekdays.some(item => item === day);
    }else {
      return false;
    }
  };

  //Filter availability employee
  const filterPassedTime = (time) => {
    const now = new Date(); // Get current date
    const selectedDate = new Date(time);
    const day = selectedDate.getDay();
    const employee = employeeData.data.find((employeeId) => employeeId.id === staffId);
    if (employee) {
      const availabilities = employee.attributes.availabilities.data;
      const selectedAvailability = availabilities.find((a) => a.attributes.weekday === day);
      if (selectedAvailability) {
        const startTime = selectedAvailability.attributes.start_time;
        const endTime = selectedAvailability.attributes.end_time;
        const startDateTime = setHours(setMinutes(selectedDate, 0), parseInt(startTime.slice(0, 2), 10));
        const endDateTime = setHours(setMinutes(selectedDate, 0), parseInt(endTime.slice(0, 2), 10));
        let passesAvailability = selectedDate >= startDateTime && selectedDate <= endDateTime;
  
        // Check for breaks
        if (selectedAvailability.attributes.break_start_time && selectedAvailability.attributes.break_end_time) {
          const breakStartTime = selectedAvailability.attributes.break_start_time;
          const breakEndTime = selectedAvailability.attributes.break_end_time;
          const breakStartDateTime = setHours(setMinutes(selectedDate, 0), parseInt(breakStartTime.slice(0, 2), 10));
          const breakEndDateTime = setHours(setMinutes(selectedDate, 0), parseInt(breakEndTime.slice(0, 2), 10));
          passesAvailability = passesAvailability && (selectedDate < breakStartDateTime || selectedDate > breakEndDateTime);
        }
        if (selectedAvailability.attributes.break2_start_time && selectedAvailability.attributes.break2_end_time) {
          const break2StartTime = selectedAvailability.attributes.break2_start_time;
          const break2EndTime = selectedAvailability.attributes.break2_end_time;
          const break2StartDateTime = setHours(setMinutes(selectedDate, 0), parseInt(break2StartTime.slice(0, 2), 10));
          const break2EndDateTime = setHours(setMinutes(selectedDate, 0), parseInt(break2EndTime.slice(0, 2), 10));
          passesAvailability = passesAvailability && (selectedDate < break2StartDateTime || selectedDate > break2EndDateTime);
        }

      // Filter out employees whose bookings contain the selected date and time spot
      const bookings = employee.attributes.bookings.data;
      passesAvailability = passesAvailability && !bookings.some(item => {
        const bookingDate = new Date(item.attributes.bookingdate);
        return bookingDate.getTime() === selectedDate.getTime();
      });     

        return passesAvailability && selectedDate > now;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const getNextAvailableDate = () => {
    const now = new Date();
    let date = now;
    const maxDate = add(tomorrow, { months: 1 });

  
    const checkAvailability = () => {
      if (filterPassedTime(date)) {
        return date;
      } else {
        date = add(date, { days: 1 });
        if (date > maxDate) { 
          return null; // Return null or throw an error to break out of the loop
        }
        return checkAvailability();
      }
    }
  
    const nextAvailableDate = checkAvailability();


  return nextAvailableDate;
  };

  return (
    <>
      <p style={titleStyle}>Select Date and Time</p>
      <div style={estStyle}>Eastern Time Zone(EST)</div>

      <DatePicker 
      selected={dateFixed ? dateFixed : getNextAvailableDate()}
        filterTime={filterPassedTime}
        onChange={(date) => {setDateFixed(date)}  
        }
        timeIntervals={60}
        showTimeSelect
        filterDate={isWeekDay}
        minDate={tomorrow}
        maxDate={maxDate}
        highlightDates={new Date()}       
        inline
      />
      <div style={spacer}>

      </div>
      <div style={buttonArea}>
        {page > 1 && page !== 4 && (
          <button className="btn btn-secondary mx-2 shadow" onClick={() => {
            const nextPage = page - 1;
              setPage(nextPage);
          }} style={currentWindow >= 720 ? buttonStyle : buttonStyleResp}> 
            Back 
          </button>
        )}
        {page < 4 && dateFixed && dateFixed.getMinutes() === 0 && dateFixed.getSeconds() === 0 && (
          <button className="btn btn-primary mx-2 shadow" onClick={() => {
            const nextPage = page + 1;
              setPage(nextPage);
          }} style={currentWindow >= 720 ? buttonStyle : buttonStyleResp}>
            Next
          </button>
        )}
      </div>
    </>
  );
};

export default DateTime;
