import axiosInstance from '../../axios';
import { useEffect, useState } from 'react';

const useUsers = () => {
  const [usersData, setUsersData] = useState([]);
  const [errorUser, setErrorUser] = useState([]);
  const [loadingUser, setLoadingUser] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setLoadingUser(true);
      try {
        const res = await axiosInstance.get('api/users');
        const json = await res.data;
        setUsersData(json);
        setLoadingUser(false);
        setErrorUser(false);
      } catch (err) {
        setErrorUser(true);
        setLoadingUser(false);
      }
    };
    fetchData();
  }, []);

  return { loadingUser, errorUser, usersData };
};

export default useUsers;
