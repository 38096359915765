import React, { useContext } from 'react';
import FormContext from '../../../../FormContext/form.context';
import { BsCheckCircleFill } from 'react-icons/bs';
import { BsCheckCircle } from 'react-icons/bs';
import { TbSquare2 } from 'react-icons/tb';
import { TbSquare3 } from 'react-icons/tb';
import { TbSquare4 } from 'react-icons/tb';
import { TbSquareRounded1Filled } from 'react-icons/tb';
import { BiCircle } from 'react-icons/bi';

 const MainPaginationMobile = () => {
    const { page, setPage } = useContext(FormContext);
    function getPageTitle(){
      var titleGetter = "";
      switch(page){
        case 1:
          titleGetter = "Services"; 
          break;
        case 2:
          titleGetter = "Employee";
          break;
        case 3:
          titleGetter = "Date/Time";
          break;
        case 4:
          titleGetter = "Confirmation";
          break;         
      }
      return titleGetter;
    }
    var unselectedNavigation={
      fontSize: 18,
      fontWeight: 'bold',
      backgroundColor: 'white'    
    }
    var activeNavigation={
      fontSize: 18,
      fontWeight: 'bold',
      backgroundColor: '#064A72',
      color: 'white',
      borderRadius: 10,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0
    }
    var spacer={
      paddingBottom: 10,
      paddingTop: 20,
      paddingLeft: 10,
      paddingRight: 10
    }
    var titleStyle={
      fontSize: 25,
      marginBottom: 30,
      fontWeight: 'bold',
      color: 'rgb(6, 74, 114)'
    }
    var numberStyle={
      width: 40,
      height: 40,
      color: 'rgb(6, 74, 114)'
    }
    return (
      <div className="container" style={spacer}>
        
        <p style={titleStyle}>{getPageTitle()}</p>
        <div className="row" style={{width: '75vw', margin: 'auto'}}>
            <div className='col'>
                <div className='row'>
                    <div className='col-3' style={page === 1 ? unselectedNavigation : unselectedNavigation}>
                      {page > 1 ? <BsCheckCircleFill style={numberStyle}/> : <BsCheckCircle style={numberStyle}/> } 
                    </div>
                    <div className='col-3' style={page === 2 ? unselectedNavigation : unselectedNavigation}>
                      {page > 2 ? <BsCheckCircleFill style={numberStyle}/> : <BiCircle style={numberStyle}/> } 
                    </div>
                    <div className='col-3' style={page === 3 ? unselectedNavigation : unselectedNavigation}>
                      {page > 3 ? <BsCheckCircleFill style={numberStyle}/> : <BiCircle style={numberStyle}/> } 
                    </div>
                    <div className='col-3' style={page === 4 ? unselectedNavigation : unselectedNavigation}>
                      {page > 4 ? <BsCheckCircleFill style={numberStyle}/> : <BiCircle style={numberStyle}/> } 
                    </div>
                </div>
            </div>
        </div>
  
    </div>
    );
}
export default MainPaginationMobile;