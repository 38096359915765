import { toast } from 'react-toastify';
import axiosInstance from '../../axios';
import randomstring from 'randomstring';

const generatePassword = () => {
  return randomstring.generate({
    length: 6,
    charset: 'alphanumeric',
  });
};

export const createUser = async (username, name, email, phone) => {
  const password = generatePassword();

  try {
    const response = await axiosInstance.post('api/auth/local/register', {
      username: username,
      email: email,
      name: name,
      phone: phone,
      password: 'CreativeConne@!',
    });
    toast.success(`Customer created successfully`, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    toast.error(`Failed to create customer ${error}`, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};
