import { toast } from 'react-toastify';
import axiosInstance from '../../axios';

export const updateAvailability = async (
  id,
  weekday,
  start_time,
  end_time,
  break_start_time,
  break_end_time,
  break2_start_time,
  break2_end_time
) => {
  try {
    const data = {
      weekday: weekday,
      start_time: start_time,
      end_time: end_time,
    };

    // Add break times if they exist
    if (break_start_time && break_end_time) {
      data.break_start_time = break_start_time;
      data.break_end_time = break_end_time;
    }
    if (break2_start_time && break2_end_time) {
      data.break2_start_time = break2_start_time;
      data.break2_end_time = break2_end_time;
    }

    const response = await axiosInstance.put('api/availabilities/' + id, {
      data: data,
    });
    toast.success(`Availability updated successfully`, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    toast.error(`Failed to update availability ${error}`, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};
