import React, { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import moment from 'moment';
import { deleteAvailability } from '../../../../hooks/actions/availabilities/delete';
import { createAvailability } from '../../../../hooks/actions/availabilities/create';
import { updateAvailability } from '../../../../hooks/actions/availabilities/update';

export default function ModalAvailabilities({
  modalState,
  handleClose,
  selectedEmployee,
}) {
  const [formData, setFormData] = useState({});
  const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const formatTime = (time) => {
    if (!time) {
      return '';
    }
    const momentTime = moment(time, 'HH:mm:ss.SSS');
    return momentTime.isValid() ? momentTime.format('h:mm A') : '';
  };

  const handleCreateAvailability = (e) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to create this availability?')) {
      const {
        weekday,
        start_time,
        end_time,
        break_start_time,
        break_end_time,
        break2_start_time,
        break2_end_time,
      } = formData;
      const formattedStartTime = moment(start_time, 'HH:mm').format(
        'HH:mm:ss.SSS'
      );
      const formattedEndTime = moment(end_time, 'HH:mm').format('HH:mm:ss.SSS');

      const formattedBreakStartTime = break_start_time
        ? moment(break_start_time, 'HH:mm').format('HH:mm:ss.SSS')
        : null;

      const formattedBreakEndTime = break_end_time
        ? moment(break_end_time, 'HH:mm').format('HH:mm:ss.SSS')
        : null;

      const formattedBreak2StartTime = break2_start_time
        ? moment(break2_start_time, 'HH:mm').format('HH:mm:ss.SSS')
        : null;

      const formattedBreak2EndTime = break2_end_time
        ? moment(break2_end_time, 'HH:mm').format('HH:mm:ss.SSS')
        : null;

      createAvailability(
        parseInt(selectedEmployee.id),
        weekday,
        formattedStartTime,
        formattedEndTime,
        formattedBreakStartTime,
        formattedBreakEndTime,
        formattedBreak2StartTime,
        formattedBreak2EndTime
      );
      handleClose();
    }
  };

  function getAvailabilityById(obj, id) {
    const availabilities = obj.attributes.availabilities.data;
    for (let i = 0; i < availabilities.length; i++) {
      if (availabilities[i].id === id) {
        return availabilities[i];
      }
    }
    return null;
  }

  const handleEditAvailability = (id) => {
    if (window.confirm('Are you sure you want to update this availability?')) {
      const {
        weekday,
        start_time,
        end_time,
        break_start_time,
        break_end_time,
        break2_start_time,
        break2_end_time,
      } = formData;

      const existingAvailability = getAvailabilityById(selectedEmployee, id);

      const formattedStartTime = moment(
        start_time || existingAvailability.start_time,
        'HH:mm'
      ).format('HH:mm:ss.SSS');
      const formattedEndTime = moment(
        end_time || existingAvailability.end_time,
        'HH:mm'
      ).format('HH:mm:ss.SSS');

      const formattedBreakStartTime = break_start_time
        ? moment(break_start_time, 'HH:mm').format('HH:mm:ss.SSS')
        : existingAvailability.break_start_time
        ? moment(existingAvailability.break_start_time, 'HH:mm:ss.SSS').format(
            'HH:mm:ss.SSS'
          )
        : null;

      const formattedBreakEndTime = break_end_time
        ? moment(break_end_time, 'HH:mm').format('HH:mm:ss.SSS')
        : existingAvailability.break_end_time
        ? moment(existingAvailability.break_end_time, 'HH:mm:ss.SSS').format(
            'HH:mm:ss.SSS'
          )
        : null;

      const formattedBreak2StartTime = break2_start_time
        ? moment(break2_start_time, 'HH:mm').format('HH:mm:ss.SSS')
        : existingAvailability.break2_start_time
        ? moment(existingAvailability.break2_start_time, 'HH:mm:ss.SSS').format(
            'HH:mm:ss.SSS'
          )
        : null;

      const formattedBreak2EndTime = break2_end_time
        ? moment(break2_end_time, 'HH:mm').format('HH:mm:ss.SSS')
        : existingAvailability.break2_end_time
        ? moment(existingAvailability.break2_end_time, 'HH:mm:ss.SSS').format(
            'HH:mm:ss.SSS'
          )
        : null;

      updateAvailability(
        id,
        weekday ? weekday : existingAvailability.weekday,
        start_time ? formattedStartTime : existingAvailability.start_time,
        end_time ? formattedEndTime : existingAvailability.end_time,
        break_start_time
          ? formattedBreakStartTime
          : existingAvailability.break_start_time,
        break_end_time
          ? formattedBreakEndTime
          : existingAvailability.break_end_time,
        break2_start_time
          ? formattedBreak2StartTime
          : existingAvailability.break2_start_time,
        break2_end_time
          ? formattedBreak2EndTime
          : existingAvailability.break2_end_time
      );

      handleClose();
    }
  };

  const handleDeleteAvailability = (id) => {
    if (window.confirm('Are you sure you want to delete this availability?')) {
      deleteAvailability(id);
      handleClose();
    }
  };

  return (
    <div className={`modal-${modalState ? 'show' : 'hide'}`}>
      <div className={`popup-${modalState ? 'show' : 'hide'}`}>
        <button className="close-btn" onClick={handleClose}>
          <GrClose style={{ width: 30, height: 30 }} />
        </button>
        <div style={{ fontWeight: 'bold', fontSize: 30 }}>
          Edit Availability Employee ID#{selectedEmployee.id}
        </div>
        <form>
          <div className="table-responsive">
            <table className="table table-striped table-responsive">
              <thead>
                <tr>
                  <th scope="col" className="text-center text-light">
                    Weekday
                  </th>
                  <th scope="col" className="text-center text-light">
                    Start Time
                  </th>
                  <th scope="col" className="text-center text-light">
                    End Time
                  </th>
                  <th scope="col" className="text-center text-light">
                    Break Start Time
                  </th>
                  <th scope="col" className="text-center text-light">
                    Break End Time
                  </th>
                  <th scope="col" className="text-center text-light">
                    Break 2 Start Time
                  </th>
                  <th scope="col" className="text-center text-light">
                    Break 2 End Time
                  </th>
                  <th scope="col" className="text-center text-light">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {selectedEmployee.attributes.availabilities.data.map(
                  (availability) => (
                    <tr key={availability.id}>
                      <td>
                        <select
                          name="weekday"
                          defaultValue={availability.attributes.weekday}
                          onChange={(e) => changeHandler(e)}
                          required
                        >
                          {weekdays.map((weekday, index) => (
                            <option key={index} value={index}>
                              {weekday}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <input
                          type="time"
                          name="start_time"
                          defaultValue={availability.attributes.start_time}
                          step="3600"
                          onChange={(e) => changeHandler(e)}
                          required
                        />
                      </td>
                      <td>
                        <input
                          type="time"
                          name="end_time"
                          defaultValue={availability.attributes.end_time}
                          step="3600"
                          onChange={(e) => changeHandler(e)}
                          required
                        />
                      </td>
                      <td>
                        <input
                          type="time"
                          name="break_start_time"
                          defaultValue={
                            availability.attributes.break_start_time
                          }
                          step="3600"
                          onChange={(e) => changeHandler(e)}
                        />
                      </td>
                      <td>
                        <input
                          type="time"
                          name="break_end_time"
                          defaultValue={availability.attributes.break_end_time}
                          step="3600"
                          onChange={(e) => changeHandler(e)}
                        />
                      </td>
                      <td>
                        <input
                          type="time"
                          name="break2_start_time"
                          defaultValue={
                            availability.attributes.break2_start_time
                          }
                          step="3600"
                          onChange={(e) => changeHandler(e)}
                        />
                      </td>
                      <td>
                        <input
                          type="time"
                          name="break2_start_time"
                          defaultValue={availability.attributes.break2_end_time}
                          step="3600"
                          onChange={(e) => changeHandler(e)}
                        />
                      </td>
                      <td>
                        <div className="d-flex justify-content-center my-2">
                          <button
                            className="btn btn-warning w-100"
                            onClick={() =>
                              handleEditAvailability(availability.id)
                            }
                          >
                            Edit
                          </button>
                        </div>

                        <div className="d-flex justify-content-center my-2">
                          <button
                            className="btn btn-danger w-100"
                            onClick={() => {
                              handleDeleteAvailability(availability.id);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                )}
                <tr style={{ backgroundColor: '#f0f8ff' }}>
                  <td>
                    <select
                      name="weekday"
                      onChange={(e) => changeHandler(e)}
                      required
                    >
                      <option value="">Select a value...</option>
                      {weekdays.map((weekday, index) => (
                        <option key={index} value={index}>
                          {weekday}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="time"
                      name="start_time"
                      step="3600"
                      onChange={(e) => changeHandler(e)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      name="end_time"
                      step="3600"
                      onChange={(e) => changeHandler(e)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      name="break_start_time"
                      step="3600"
                      onChange={(e) => changeHandler(e)}
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      name="break_end_time"
                      step="3600"
                      onChange={(e) => changeHandler(e)}
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      name="break2_start_time"
                      step="3600"
                      onChange={(e) => changeHandler(e)}
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      name="break2_end_time"
                      step="3600"
                      onChange={(e) => changeHandler(e)}
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-primary w-100"
                      onClick={handleCreateAvailability}
                    >
                      Create
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
  );
}
