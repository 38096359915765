import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { forgetPassword } from '../../../hooks/actions/authentication/forget_password';
import Header from '../../Homepage/Header/index';
import Footer from '../../Homepage/Footer/index';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import {AiFillHome} from 'react-icons/ai'
import { icons } from 'react-icons/lib';

const ForgotPassword = () => {
  const currentWindow = window.innerWidth;
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await forgetPassword(email);
      if (response) {
        setSuccess(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  var mainFrame = {
    backgroundColor: 'white',
    padding: 50,
    borderRadius: 20,
    width: '30%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 200,
    marginBottom: 200,
  };
  var mainFrameResp = {
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 20,
    width: '95%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 40,
    marginBottom: 40,
  };
  var backgroundSettings = {
    backgroundImage:
      'linear-gradient(to top, #fdd570, #fdce61, #fcc752, #fcc042, #fcb931)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '70vh',
    /* end body required properties */
    margin: 'auto',
  };
  var mainBox = {
    flexGrow: 1,
  };
  var buttonStyle = {
    marginTop: '2vh',
  };
  var buttonStyleResp = {
    marginTop: '5vh',
  };
  var iconStyle={
    width: 50,
    height: 50,
    color: 'rgb(6, 74, 114)'
  }
  return (
    <div style={mainBox}>
      <div style={backgroundSettings}>
        <Header />
        <ToastContainer limit={1} />
        <div
          className="shadow"
          style={currentWindow >= 720 ? mainFrame : mainFrameResp}
        >
          <h1 style={{ marginBottom: 30 }}>Forgot password</h1>
          {success ? (
            <div>
              <p>
              Please verify your email address and follow the instructions in
              the email to proceed.
                
                
              </p>
              <div>
              <Link
              to="/login"
              style={{ width: '4vh', height: '3vw' }}
              >
                <AiFillHome style={iconStyle}/>
              </Link>
              
              </div>
            </div>

              
            
            
            
          ) : (
            <form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="identifier">
                <Form.Label className="d-flex justify-content-start">
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="Enter your email address"
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
              <button
                className="btn btn-primary"
                style={currentWindow >= 720 ? buttonStyle : buttonStyleResp}
                type="submit"
              >
                Submit
              </button>
            </form>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
