import React from 'react';
import Icon from "../../../images/errorIcon.png"


const ErrorPage = () => {
  var redirectMessage={
    color: 'gray'
  }
  var confirmationTitle={
    padding: 20
  }
  var imgStyle={
    width: 200,
    height: 230,
    alt: "Creative Connex Icon",
    paddingBottom: 40
  }
  var backgroundSettings={
    minHeight: '60vh',
    paddingBottom: 10
  }  
  

  return (
    <div style={backgroundSettings}>
        <h2 style={confirmationTitle}>Sorry! We are having an error</h2>
        <img src={Icon} style={imgStyle}/>
        <h5>Our system has found an error and we will not be able to proceed with your appointment</h5>
        <p style={redirectMessage}>Refresh the page or restart the process</p>
        <br/>
    </div>
  );
};

export default ErrorPage;