import React from 'react';
import Header from './Header';
import Footer from './Footer';
import MainSteps from './Bookings/MainSteps';


const HomePage = () => {
  var backgroundSettings={
    backgroundImage: "linear-gradient(to top, #fdd570, #fdce61, #fcc752, #fcc042, #fcb931)",
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    /* end body required properties */
    margin: 'auto'
  }  
  return (
    <div style={backgroundSettings}>
        <Header />
        <MainSteps />
        <Footer />
    </div>
  );
};

export default HomePage;