import React, {useState, useEffect } from 'react';
import  HashLoader  from 'react-spinners/HashLoader';

// CSS FOR LOADER
const CSSProperties = {
    display: "block",
    margin: "auto",
    paddingTop: 120,
    paddingBottom: 250
  };
  
const Loader = () => {

  const [loading1, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)

    }, 1500)
  }, [])

    return (
      <div>
        <HashLoader
          cssOverride={CSSProperties}
          color={"#fdc021"}
          size={150}
        />
      </div>
    )
}

export default Loader;