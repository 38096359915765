import React, { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import useServices from '../../../../hooks/actions/serv/get';
import { toast } from 'react-toastify';

export default function ModalSubServices({
  modalState,
  handleClose,
  handleSave,
  handleDelete,
  selected,
  isCreateModal,
}) {
  const [formData, setFormData] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const currentWindow = window.innerWidth;

  const { isServiceLoading, hasServiceError, serviceData } = useServices();

  //API status messages
  if (isServiceLoading) {
    return;
  } else if (hasServiceError) {
    return;
  }

  let selectServicesId;
  try {
    selectServicesId = selected.attributes.services.data.map((item) => item.id);
  } catch (error) {}

  var formGrouWrapper = {
    marginTop: 20,
    marginBottom: 20,
  };
  var modalResp={
    width: '95vw'
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isDelete) {
      if (selected.attributes.bookings.data.length > 0) {
        toast.error(`You cannot delete this service. Please contact admin.`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        handleDelete(selected.id);
      }
    } else {
      if (isCreateModal) {
        handleSave(formData);
      } else {
        handleSave(selected.id, formData);
      }
    }
    handleClose();
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const changeHandlerOptions = (event) => {
    const { name, options } = event.target;
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    setFormData((prevState) => ({ ...prevState, [name]: selectedValues }));
  };

  return (
    <div className={`modal-${modalState ? 'show' : 'hide'}`}>
      <div className={`popup-${modalState ? 'show' : 'hide'}`} style={currentWindow >= 720 ? null : modalResp}>
        <button className="close-btn" onClick={handleClose}>
          <GrClose style={{ width: 30, height: 30 }} />
        </button>
        <div style={{ fontWeight: 'bold', fontSize: 30 }}>
          {isCreateModal ? 'Create Service' : `Edit Service ID#${selected.id}`}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col">
              <div className="form-group text-start" style={formGrouWrapper}>
                <label>Service Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  defaultValue={isCreateModal ? '' : selected.attributes.name}
                  onChange={changeHandler}
                  required
                />
              </div>
              <div className="form-group text-start" style={formGrouWrapper}>
                <label>Service Description</label>
                <input
                  type="textarea"
                  className="form-control"
                  id="description"
                  name="description"
                  defaultValue={
                    isCreateModal ? '' : selected.attributes.description
                  }
                  onChange={changeHandler}
                  required
                />
                <br />
                <label htmlFor="servicesId">Select categories:</label>
                <select
                  className="form-select"
                  id="servicesId"
                  name="servicesId"
                  defaultValue={isCreateModal ? [] : selectServicesId}
                  onChange={changeHandlerOptions}
                  multiple
                  required
                >
                  {serviceData.data.map((service) => (
                    <option key={service.id} value={service.id}>
                      {service.attributes.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <button
            type="submit"
            style={{ marginRight: 15 }}
            className="btn btn-warning"
          >
            {isCreateModal ? 'Create' : 'Update'}
          </button>
          {!isCreateModal && (
            <button
              type="submit"
              style={{ marginRight: 15 }}
              className="btn btn-danger"
              onClick={() => {
                setIsDelete(true);
              }}
            >
              Delete
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
