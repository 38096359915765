import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Header from '../../Homepage/Header/index';
import Footer from '../../Homepage/Footer/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import handleLogin from '../../../hooks/actions/authentication/auth';
import { RiLogoutBoxRFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const initialUser = { password: '', identifier: '' };
const Login = () => {
  const location = useLocation();
  const [user, setUser] = useState(initialUser);
  const [userLogged, setUserLogged] = useState(null);
  const navigate = useNavigate();
  const currentWindow = window.innerWidth;
  const message = location.state ? location.state.message : null;

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUserLogged(JSON.parse(storedUser));
    }
  }, []);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setUser({ ...user, [name]: value });
  };

  var mainFrame = {
    backgroundColor: 'white',
    padding: 50,
    borderRadius: 20,
    width: '30%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 200,
    marginBottom: 200,
    flexGrow: 1,
  };
  var mainFrameResp = {
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 20,
    width: '95%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 40,
    marginBottom: 40,
    flexGrow: 1,
  };
  var backgroundSettings = {
    backgroundImage:
      'linear-gradient(to top, #fdd570, #fdce61, #fcc752, #fcc042, #fcb931)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    /* end body required properties */
    margin: 'auto',
  };
  var mainBox = {
    flexGrow: 1,
  };
  var loginTitle = {
    marginBottom: 30,
  };
  var buttonStyle = {
    marginTop: 20,
    marginBottom: 20,
    width: '10vw',
    height: '7vh',
  };
  var buttonStyleResp = {
    marginTop: 20,
    marginBottom: 20,
    width: '50vw',
    height: '7vh',
  };
  var titleDecorationRemove = {
    textDecoration: 'none',
  };
  var mainIcons = {
    width: 80,
    height: 80,
    color: 'rgb(6, 74, 114)',
    marginBottom: 15,
  };
  var mainIconsResp = {
    width: 80,
    height: 80,
    color: 'rgb(6, 74, 114)',
    marginBottom: 15,
    marginLeft: 30,
    marginRight: 30,
  };
  var mainIconsTitle = {
    fontWeight: 'bold',
    marginBottom: 60,
    marginTop: 10,
    color: 'black',
  };
  var mainIconsTitleResp = {
    fontWeight: 'bold',
    marginBottom: 25,
    marginTop: 10,
    color: 'black',
  };

  return (
    <div style={mainBox}>
      <div style={backgroundSettings}>
        <Header />
        <ToastContainer limit={1} />
        {message && (
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        )}
        {userLogged ? (
          <div
            className="shadow"
            style={currentWindow >= 720 ? mainFrame : mainFrameResp}
          >
            <p>Hello, {userLogged.username}!</p>
            <p>Do you want to log out?</p>
            <a style={titleDecorationRemove} href="/logout">
              <div>
                <RiLogoutBoxRFill
                  style={currentWindow >= 720 ? mainIcons : mainIconsResp}
                />
                <div
                  style={
                    currentWindow >= 720 ? mainIconsTitle : mainIconsTitleResp
                  }
                >
                  Logout
                </div>
              </div>
            </a>
          </div>
        ) : (
          <div
            className="shadow"
            style={currentWindow >= 720 ? mainFrame : mainFrameResp}
          >
            <h2 style={loginTitle}>Login</h2>
            <Form>
              <Form.Group className="mb-3" controlId="identifier">
                <Form.Label className="d-flex justify-content-start">
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  name="identifier"
                  value={user.identifier}
                  onChange={handleChange}
                  placeholder="Enter your email"
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label className="d-flex justify-content-start">
                  Password
                </Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={user.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>

              <Button
                style={currentWindow >= 720 ? buttonStyle : buttonStyleResp}
                className="btn btn-primary btn- btn-block shadow"
                onClick={() => handleLogin(user, navigate, setUser, toast)}
              >
                Enter
              </Button>
            </Form>
            <Link to="/forgot-password">Forgot password?</Link>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default Login;
