import React from 'react';
import { FaSync } from 'react-icons/fa';
import './style.css';

const RefreshButton = () => (
  <button className="RefreshButton" onClick={() => window.location.reload()}>
    <FaSync />
  </button>
);

export default RefreshButton;
