import { toast } from 'react-toastify';
import axiosInstance from '../../axios';

export const forgetPassword = async (email) => {
  try {
    const response = await axiosInstance.post('/api/auth/forgot-password', {
      email,
    });
    toast.success(
      `Please verify your email address and follow the instructions in the email to proceed.`,
      {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
      }
    );
    return response.data;
  } catch (error) {
    toast.error(`Failed to send email. Try again later`, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    });
    return null;
  }
};
