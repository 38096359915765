import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserData } from '../../hooks/actions/authentication/user';
import Loader from '../Homepage/Loading';

export const storeUser = (data) => {
  localStorage.setItem(
    'user',
    JSON.stringify({
      username: data.user.username,
      jwt: data.jwt,
    })
  );
};

export const userData = () => {
  const stringfiedUser = localStorage.getItem('user') || '""';
  return JSON.parse(stringfiedUser || {});
};

export const Protector = ({ Component, roles }) => {
  const [authorized, setAuthorized] = useState(false);
  const navigate = useNavigate();

  const { jwt } = userData();

  useEffect(() => {
    const checkUserRole = async () => {
      if (!jwt) {
        setAuthorized(false);
        navigate('/login', {
          state: { message: 'You need to log in to access the requested page' },
        });
        return;
      }

      const userData = await fetchUserData(jwt);
      if (!userData) {
        setAuthorized(false);
        navigate('/login', {
          state: { message: 'You need to log in to access the requested page' },
        });
        return;
      }

      if (roles.includes(userData.role.type)) {
        setAuthorized(true);
      } else {
        setAuthorized(false);
        navigate('/login', {
          state: {
            message: `You need ${roles.join(
              ' or '
            )} privileges to access the requested page`,
          },
        });
      }
    };

    checkUserRole();
  }, [jwt, navigate, roles]);

  return authorized ? <Component /> : <Loader />;
};
