import React from 'react';
import '../404/style.css';

const NotFoundComponent = () => {
  return (
    <div className="not-found">
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>Sorry, the page you are looking for could not be found.</p>
      <a href="/">Click here to go back the bookings page</a>
      <br />
      <a href="https://creativeconnex.ca/home">
        Click here to go Creative Connex website
      </a>
    </div>
  );
};

export default NotFoundComponent;
