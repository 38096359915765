import React, { useContext } from 'react';
import FormContext from '../../../FormContext/form.context';
import { BsArrowRightCircleFill } from 'react-icons/bs';
import { BsArrowRightCircle } from 'react-icons/bs';


 const MainPagination = () => {
    const { page, setPage } = useContext(FormContext);
    var unselectedNavigation={
      fontSize: 20,
      fontWeight: 'bold',
      backgroundColor: 'white'    
    }
    var activeNavigation={
      fontSize: 20,
      fontWeight: 'bold',
      backgroundColor: '#064A72',
      color: 'white',
      borderRadius: 10,
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 2,
      paddingBottom: 2
    }
    var spacer={
      paddingBottom: 40,
      paddingTop: 20,
      paddingLeft: 40,
      paddingRight: 40
    }
    var titleStyle={
      fontSize: 25,
      marginBottom: 30,
      fontWeight: 'bold'
    }
    var figureStyle={
      color: 'rgb(6, 74, 114)'
    }
    return (
      <div className="container" style={spacer}>
        <p style={titleStyle}>New Booking</p>
      <ul className="nav nav-pills nav-justified">
        <li className="nav-item">
          <p style={page === 1 ? activeNavigation : unselectedNavigation}>
            Services
          </p>
        </li>
        <li className="nav-item">
        {page >= 2 ? <BsArrowRightCircleFill style={figureStyle}/> : <BsArrowRightCircle style={figureStyle}/>}
        </li>
        <li className="nav-item">
          <p className={page === 2 ? 'nav-link active' : 'nav-link-disabled'} style={page === 2 ? activeNavigation : unselectedNavigation}>
            Employee
          </p>
        </li>
        <li className="nav-item">
          {page >= 3 ? <BsArrowRightCircleFill style={figureStyle}/> : <BsArrowRightCircle style={figureStyle}/>}
        </li>
        <li className="nav-item">
          <p className={page === 3 ? 'nav-link active' : 'nav-link-disabled'} style={page === 3 ? activeNavigation : unselectedNavigation}>
            Date/Time
          </p>
        </li>
        <li className="nav-item">
          {page >= 4 ? <BsArrowRightCircleFill style={figureStyle}/> : <BsArrowRightCircle style={figureStyle}/>}
        </li>
        <li className="nav-item">
          <p className={page === 4 ? 'nav-link active' : 'nav-link-disabled'} style={page === 4 ? activeNavigation : unselectedNavigation}>
            Confirmation
          </p>
        </li>
      </ul>
    </div>
    );
}
export default MainPagination;