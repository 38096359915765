import React from "react";
import { ImFacebook2 } from 'react-icons/im';
import { GrInstagram } from 'react-icons/gr';
import { ImTwitter } from 'react-icons/im';
import { ImLinkedin2 } from 'react-icons/im';

 const Footer = () => {
  const currentWindow = window.innerWidth;
  var mainFrame={
    padding: 40,
    backgroundColor: '#064a72',
    color: 'white'
  }
  var iconStyle={
    width: 25,
    height: 25,
    marginRight: 10,
    color: 'white',
    marginTop: 20
  }
  var contentArea={
    textAlign: 'start',
    marginLeft: '6em'
  }
  var contentAreaResp={
    textAlign: 'start',
    marginLeft: '0em'
  }
  var linkStyle={
    textDecoration: 'none',
    color: 'white',
    marginTop: 10,
    fontFamily: 'Work Sans",sans-serif'
  }
  var titleLine={
    marginTop: -18,
    marginBotom: 5,
    fontSize: 20
  }
  var linkLi={
    marginTop: 10,
    color: 'white',
    fontFamily: 'Work Sans",sans-serif'
  }
    return (
      <div style={mainFrame}>
        <div className="container">
          <div className="row"  style={currentWindow >= 720 ? contentArea : contentAreaResp}>
            {/* Left Column */}
            <div className="col">
            <div>NEED HELP?</div>
            <div style={titleLine}>_____________________________</div>
            <ul className="list-unstyled">
              <a href="https://creativeconnex.ca/static/media/Privacy-Policy.06177bc3.pdf" style={linkStyle}><li style={linkLi}>Privacy Policy</li></a>
              <a href="https://creativeconnex.ca/static/media/FAQ.cbd82868.pdf" style={linkStyle}><li style={linkLi}>FAQs</li></a>
              <a href="https://creativeconnex.ca/contact/professional" style={linkStyle}><li style={linkLi}>Career</li></a>
            </ul>
            </div>
            {/* Center Column */}
            <div className="col">
            <div>AFFILIATED</div>
            <div style={titleLine}>_____________________________</div>
            <ul className="list-unstyled">
              <a href="https://www.mugwumps.online/" style={linkStyle}><li style={linkLi}>Mugwumps</li></a>
              <a href="https://creativeconnex.ca/home#" style={linkStyle}><li style={linkLi}>Affiliate Program</li></a>
              <a href="https://creativeconnex.ca/home#" style={linkStyle}><li style={linkLi}>Our Affiliates</li></a>
            </ul>
            </div>
            {/* Rigth Column */}
            <div className="col">
            <div>SOCIALS</div>
            <div style={titleLine}>_____________________________</div>
            <a href="https://www.facebook.com/CreativeConnex/?view_public_for=105873884736250"><ImFacebook2 style={iconStyle}/></a>
            <a href="https://www.instagram.com/creative_connex/"><GrInstagram style={iconStyle}/></a>
            <a href="https://twitter.com/creative_connex"><ImTwitter style={iconStyle}/></a>
            <a href="https://www.linkedin.com/company/creativeconnex/"><ImLinkedin2 style={iconStyle} /></a>
            </div>
          </div>
        </div>
      </div>
    );
}
export default Footer;