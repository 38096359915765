import React, {useState} from "react";
import { RiLogoutBoxRFill } from 'react-icons/ri';
import { FaUserAlt } from 'react-icons/fa';

const Header = () => {
  const currentWindow = window.innerWidth;
  var localStorageValue = localStorage.getItem('user');
  var headerStyle={
    padding: 15,
    backgroundColor: 'white'
  }
  var imgStyle={
    width: 100,
    height: 50,
    alt: "Creative Connex Icon"
  }
  var LoginOutImage={
    width: 30,
    height: 30,
    margin: 'auto',
    color: 'rgb(6, 74, 114)',
    alt: "Creative Connex Icon"
  }

  var loginStyle={
    display: 'block'
  }
  var logoutStyle={
    display: 'none'
  }
  var titleDecorationRemove = {
    textDecoration: 'none',
    color: 'rgb(6, 74, 114)'
  }
    return (
      <div>
        <div className="shadow-sm" style={headerStyle}>
          <div className="row">
            <div className="col">
            < img src="https://creativeconnex.ca/static/media/logo.79a1e05b.png" className={'d-flex justify-content-start'} style={imgStyle}/>   
            </div>
            <div className={currentWindow >= 720 ? "col-1" : "col-3"} style={localStorageValue ? logoutStyle : loginStyle}>
            <a href="/login" style={titleDecorationRemove}>
              <div>
                <FaUserAlt style={LoginOutImage}/>
              </div>
              <div style={{fontWeight: 'bold'}}>
                Login
              </div>
            </a> 
            </div>
            <div className={currentWindow >= 720 ? "col-1" : "col-3"} style={localStorageValue ? loginStyle : logoutStyle}>
              <a href="/logout" style={titleDecorationRemove}>
                <div>
                  <RiLogoutBoxRFill style={LoginOutImage}/>
                </div>
                <div style={{fontWeight: 'bold'}}>
                  Logout
                </div>
              </a>
              
            </div>
          </div>
        </div>       
      </div>
      
    );
}

export default Header
