import axiosInstance from '../../axios';
import { useEffect, useState } from 'react';

const useEmployees = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [hasEmployeeError, setHasEmployeeError] = useState(false);
  const [isEmployeeLoading, setIsEmployeeLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsEmployeeLoading(true);

      try {
        const res = await axiosInstance.get('api/employees?populate=*');
        const json = await res.data;
        setEmployeeData(json);
        setIsEmployeeLoading(false);
        setHasEmployeeError(false);
      } catch (err) {
        setHasEmployeeError(true);
        setIsEmployeeLoading(false);
      }
    };

    fetchData();
  }, []);

  return { isEmployeeLoading, hasEmployeeError, employeeData };
};

export default useEmployees;
